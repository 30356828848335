export const styles = {
    image: {
        display: 'block',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 50,
        height: 50,
        borderRadius: 4,
        objectFit: 'cover',
    },
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        lineHeight: 1.2,
        wordBreak: 'break-word'
    } 
}