import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { useFeaturesEditing } from '../../presenters/useFeaturesEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { FeaturesImageDropZone } from '../components/featuresImageDropZone';
import { ResourcesView } from 'src/modules/resources/ui/Resources';
import { links } from 'src/Links';

export const FeaturesEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { DEVELOPMENT_SIDES, features, errors, onChange, onUpdateFeatures, onRefresh } = useFeaturesEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('editFeatures')} buttonTitle={t('save')} onPress={onUpdateFeatures} />
            <Card sx={{ p: 2, mb: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4.5} md={3.5} lg={3.5}  >
                        <FeaturesImageDropZone />
                    </Grid>
                    <Grid item xs={12} sm={7.5} md={8.5} lg={8.5} >
                        <TextInput label={t('name')} name='name' onChange={onChange} value={features?.name} error={errors} required />
                        <TextInput label={t('description')} name='description' onChange={onChange} value={features?.description} />
                        <TextInput label={t('slug')} name='slug' onChange={onChange} value={features?.slug} error={errors} required />
                        <TextInput label={t('estimate')} name='estimate' onChange={onChange} value={features?.estimate} error={errors} />
                        <TextInput label={t('estimateMarket')} name='estimateMarket' onChange={onChange} value={features?.estimate_market} error={errors} />
                        <TextInput label={t('price')} name='price' onChange={onChange} value={features?.price} error={errors} />
                        <Select label={t('developmentSide')} name='development_side' onChange={onChange} value={features?.development_side || ''} options={DEVELOPMENT_SIDES} />
                    </Grid>
                </Grid>
            </Card>
            <ResourcesView resources={features?.content || []} link={links.features} entityId={features?.id} onUpdate={onRefresh} />
        </Container>
    );
})
