import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createNewsUseCase } from "../useCases/createNewsUseCase";
import { z } from 'zod';
import { newsModel } from "../entity/news/NewsModel";
import { getNewsCategoriesUseCase } from "src/modules/newsCategories/useCases/getNewsCategoriesUseCase";
import { INewsCategory } from "src/modules/newsCategories/entity/newsCategories/INewsCategory";
import { newsCategoryModel } from "src/modules/newsCategories/entity/newsCategories/NewsCategoryModel";

const newsSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    description: z.string().nullable(),
    slug: z.string().min(1, { message: "Not empty" }),
    available: z.boolean(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }),
});

export const useNewsCreating = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '', category_id: '', });
    const news = newsModel.newsOne;
    const navigate = useNavigate();
    const categories = newsCategoryModel.newsCategories;

    useEffect(() => {
        if (!categories.length) {
            getNewsCategoriesUseCase();
        }
        newsModel.newsOne = { name: '', description: '', slug: '', available: false, image: null, category_id: null } as any;
        return () => { newsModel.newsOne = null; }
    }, []);

    const parent = categories?.find((item: INewsCategory) => item.id === news?.category_id);

    const options = categories?.map((item: INewsCategory) => ({ id: item.id, name: item.name, value: item.id }));

    const onChange = (value: string, field: string) => {
        if (newsModel.newsOne) {
            newsModel.newsOne = { ...newsModel.newsOne, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onCreateNews = async () => {
        setLoading(true);
        const result: any = newsSchema.safeParse({ ...newsModel.newsOne });
        if (result.success && newsModel.newsOne) {
            const result = await createNewsUseCase(newsModel.newsOne as any);
            if (!result?.isError) {
                navigate(`/dashboard/news/edit/${result?.data.id}`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    return { news, errors, onChange, onCreateNews, parent, options };

}