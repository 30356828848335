import React, { FC } from 'react';
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Tooltip, IconButton, OutlinedInput, InputAdornment } from '@mui/material';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    gap: 15,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: '100%',
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        // width: 320,
        // @ts-ignore
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

interface IProps {
    search: string,
    placeholder: string,
    onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const ListToolbar: FC<IProps> = ({ search, placeholder, onSearch }) => {
    const { t } = useUiContext();

    return (
        <StyledRoot >
            <StyledSearch
                value={search}
                onChange={onSearch}
                placeholder={placeholder}
                startAdornment={
                    <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                }
            />
            {/* <Tooltip title="Filter list">
                <IconButton>
                    <Iconify icon="ic:round-filter-list" />
                </IconButton>
            </Tooltip> */}
        </StyledRoot>
    );
}
