import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { NewsHomeHeader } from '../components/newsHomeHeader';
import { Outlet } from 'react-router-dom';
import { useNewsLayout } from '../../presenters/useNewsLayout';

export const NewsLayout: FC = observer(() => {
    const { t } = useUiContext();
    const { categories } = useNewsLayout();

    return (
        <Container maxWidth="lg">
            <NewsHomeHeader title={t('news')} sections={categories} />
            <Outlet />
        </Container>
    );
})
