import { Stack, Typography } from "@mui/material";
import React from "react";
import { FC } from "react";

interface IProps {
    title: string;
    value: string | undefined;
};

export const DetailData: FC<IProps> = ({ title, value }) => {

    return (
        <Stack direction="row" alignItems='baseline' spacing={1}>
            <Typography variant='body1'  fontWeight={600} sx={{ whiteSpace: 'nowrap' }}>{title}: </Typography>
            <Typography variant='body1' noWrap>{value}</Typography>
        </Stack>
    )
};