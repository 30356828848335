import React, { FC } from 'react';
import { Box, Card, Link, Typography, Stack, IconButton } from '@mui/material';
import { ContextMenu } from 'src/UIKit/ContextMenu';
import { Iconify } from 'src/UIKit/Iconify';
import { styles } from './styles';
import { INews } from 'src/modules/news/entity/news/INews';
import { useNewsTableItem } from 'src/modules/news/presenters/useNewsTableItem';

interface IProps {
    item: INews;
}

export const NewsCard: FC<IProps> = ({ item }) => {
    const { open, onOpenMenu, onCloseMenu, onDelete, onEdit, onActiveSwitch } = useNewsTableItem(item);

    const date = new Date(item.created_at).toLocaleDateString();

    return (
        <Card>
            <Box sx={styles.imageContainer}>
                {item.image?.path ? <img src={item.image?.path} style={styles.image} /> : null}
                <IconButton size="large" color="inherit" onClick={onOpenMenu} sx={styles.contextMenu}>
                    <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
            </Box>

            <Stack spacing={2} sx={{ p: 3 }}>
                <Typography variant="subtitle2" noWrap>{item.name}</Typography>
                <Typography variant='caption'>{date}</Typography>
            </Stack>
            <ContextMenu open={open} onClose={onCloseMenu} onDelete={onDelete} onEdit={onEdit} />
        </Card>
    );
}