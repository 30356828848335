import { Box, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { ContextMenu } from 'src/UIKit/ContextMenu';
import { useVacanciesTableItem } from '../../../presenters/useVacanciesTableItem';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';
import { IVacancies } from '../../../entity/vacancies/IVacancies';

interface IProps {
    item: IVacancies;
}

export const VacancyTableRow: FC<IProps> = memo(({ item }) => {
    const { t } = useUiContext();
    const { open, onOpenMenu, onCloseMenu, onDelete, onEdit, onActiveSwitch } = useVacanciesTableItem(item);

    return (
        <>
            <TableRow hover >
                <TableCell align="center">
                    <Typography variant="subtitle2" noWrap>{item.name}</Typography>
                </TableCell>
                <TableCell component="th" scope="row" padding="none" align="center">
                    <Typography variant="subtitle2" noWrap>{item.salary}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.application_deadline}</Typography>
                </TableCell>
                <TableCell align={'center'} >
                    <Typography variant="subtitle2" noWrap>{item.status}</Typography>
                </TableCell>
                <TableCell align={'center'} width={120}>
                    <Box width={120} >
                        <IOSSwitch label={t('active')} name="available" onChange={onActiveSwitch} checked={item.available} />
                    </Box>
                </TableCell>
                <TableCell align="right">
                    <IconButton size="large" color="inherit" onClick={onOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <ContextMenu open={open} onClose={onCloseMenu} onDelete={onDelete} onEdit={onEdit} />
        </>
    );
})
