import { toastService } from "src/libraries/toast";
import { userService } from "../entity/UserService";
import { userModel } from "../entity/UserModel";

export const getUserUseCase = async (id: number | string) => {
    try {
        const response = await userService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            userModel.user = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getUserUseCase: ', error);
    }
};  