export const styles = {
    image: {
        display: 'block',
        alignSelf: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 50,
        height: 50,
        borderRadius: 4,
        objectFit: 'cover',
    }
}