import { FormControl, InputLabel, MenuItem, SelectChangeEvent, Select as SelectMui, SxProps, Theme, Typography, TypographyProps } from '@mui/material';
import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { useUiContext } from 'src/UIProvider';

interface IProps {
    name: string;
    label: string;
    value?: string;
    options: { id: any; name: string | number; value?: any }[];
    required?: boolean;
    onChange: (value: any, name: string) => void;
    error?: { [key: string]: string };
    typographyProps?: TypographyProps;
    sx?: SxProps<Theme>;
    isDisabled?: boolean;
}

export const Select: FC<IProps> = observer(({ name, label, options, value = '', onChange, error, typographyProps, required, sx, isDisabled }) => {
    const { t } = useUiContext();

    const onSelect = (event: SelectChangeEvent<string>) => {
        const id = options?.find((item) => item.name === event.target.value)?.id;
        onChange(id, name);
    };

    return (
        <>
            <Typography variant='h6'>{label}</Typography>
            <FormControl fullWidth sx={{ my: 1, ...sx }} disabled={isDisabled}>
                <InputLabel id={name + "InputLabel"} size='small'>{label}</InputLabel>
                <SelectMui required={false} value={value || ''} label={label} onChange={onSelect} MenuProps={{ disableScrollLock: true }} size='small'>
                    {/* {required ? null : <MenuItem value={''}>{t('common.none')}</MenuItem>} */}
                    {options?.map((item, index) => (<MenuItem value={item.name} key={'select' + item.name + item.value + index}>{item.name}</MenuItem>))}
                </SelectMui>
                <Typography minHeight={1} variant='caption' color={'red'} {...(typographyProps || {})}>{error?.[name]}</Typography>
            </FormControl>
        </>
    );
})

