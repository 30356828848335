import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from "react";
import { createNotificationUseCase } from "../useCases/createNotificationUseCase";
import { useNavigate, useParams } from "react-router-dom";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";
import { notificationsModel } from "../entity/NotificationModel";
import { IResources } from "src/modules/resources/entity/resources/IResources";
import { z } from "zod";
import { getNotificationUseCase } from '../useCases/getNotificationUseCase';
import { updateNotificationUseCase } from '../useCases/updateNotificationUseCase';

const DEFAULT_RESOURCE: any = { name: '', value: '', file: null, type: 'IMAGE', id: 0 };

export const useNotificationEdit = () => {
    const { id } = useParams();
    const { t } = useUiContext();
    const [loading, setLoading] = useState(false);
    const notification = notificationsModel.notification;
    const [resource, setResource] = useState<IResources>(DEFAULT_RESOURCE);
    const [time, setTime] = useState('');
    const [checked, setChecked] = useState(false);
    const [errors, setErrors] = useState({ title: '', body: '', time: '' });
    const navigate = useNavigate();

    const IMAGE_TYPES = [
        { name: t('notifications.link'), id: 'LINK_IMAGE' },
        { name: t('notifications.image'), id: 'IMAGE' },
    ];
    const isDeleted = !!notification?.deleted_at;
    
    const typeObject = IMAGE_TYPES?.find((item: any) => item.id === notification?.type);

    const notificationSchema = z.object({
        title: z.string().min(1, { message: t('common.error.notEmpty') }),
        body: z.string().min(1, { message: t('common.error.notEmpty') }),
    });

    useEffect(() => {
        getNotificationUseCase(Number(id)).then(() => {
            if (notificationsModel.notification) {
                if(notificationsModel.notification?.image?.source === 'OTHER'){
                    notificationsModel.notification = { ...notificationsModel.notification, type: 'LINK_IMAGE', image: notificationsModel.notification.image.path };
                }
                if(notificationsModel.notification?.image?.source === 'S3'){
                    notificationsModel.notification = { ...notificationsModel.notification, type: 'IMAGE' };
                    setResource((prev) => ({ ...prev, file: notificationsModel.notification?.image, type: 'IMAGE' }));
                }

                if(notificationsModel.notification?.audience?.time){
                    setChecked(true);
                    setTime(notificationsModel.notification?.audience?.time);
                }
            }
        });
        return () => { notificationsModel.notification = null; }
    }, []);

    useEffect(() => {
        notificationsModel.notification = { title: '', body: '', type: 'LINK_IMAGE' } as any;
        return () => { notificationsModel.notification = null; }
    }, []);

    const onChange = (value: any, field: string) => {
        if (notificationsModel.notification) {
            notificationsModel.notification = { ...notificationsModel.notification, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
            if (field === 'type') {
                notificationsModel.notification = { ...notificationsModel.notification, image: null };
                setResource((prev) => ({ ...prev, file: null }));
            }
        }
    };

    const onChangeFile = (value: any, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
    };

    const onChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        event.target.checked === false && setTime('');
    };

    const onChangeTime = (newDateTime: any) => {
        setTime(newDateTime);
    };

    const onAcceptTime = () => {
        toastService.onSuccess(t('notifications.timeSet'));
    };

    const onUpdateNotification = async () => {
        setLoading(true);
        const result: any = notificationSchema.safeParse({ title: notification?.title, body: notification?.body });
        if (result.success && notificationsModel.notification) {
            const body: any = {
                audience: "ALL",
                title: notification?.title || '',
                body: notification?.body || '',
                image: resource.file || notification?.image || 'null',
                navigation_url: notification?.navigation_url || '',
                time: time? dayjs(time).unix() + '000' : ''
            };

            const result = await updateNotificationUseCase({id, body});
            if (result) {
                navigate(`/dashboard/notifications`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        };
    };

    return {
        onUpdateNotification, time, checked, onChangeSwitch, onChangeTime, onAcceptTime, onChange, notification, resource, onChangeFile, IMAGE_TYPES,
        typeObject, errors, isDeleted
    };
};
