import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createNltdevskeletonUseCase } from "../useCases/createNltdevskeletonUseCase";
import { z } from 'zod';
import { nltdevskeletonsModel } from "../entity/nltdevskeletons/NltdevskeletonsModel";
import { getCategoriesUseCase } from "src/modules/categories/useCases/getCategoriesUseCase";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { ICategory } from "src/modules/categories/entity/categories/ICategory";
import { getNltdevskeletonUseCase } from "../useCases/getNltdevskeletonUseCase";
import { updateNltdevskeletonUseCase } from "../useCases/updateNltdevskeletonUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

export const useNltdevskeletonEditing = () => {
    const { t } = useUiContext();
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', category_id: '', image: '' });
    const nltdevskeleton = nltdevskeletonsModel.nltdevskeleton;
    const categories = categoriesModel.categories;
    const navigate = useNavigate();

    const nltdevskeletonSchema = z.object({
        name: z.string().min(1, { message: t('common.error.notEmpty') }),
        description: z.string().nullable(),
        slug: z.string().min(1, { message: t('common.error.notEmpty') }),
        category_id: z.union([z.number(), z.null()]).refine(value => value !== null, { message: t('common.error.notEmpty') }),
        available: z.boolean(),
        image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: t('common.error.fileSize') }).nullable().optional(),
    });

    useEffect(() => {
        if (!categories.length) {
            getCategoriesUseCase({ tree: true });
        }
        getNltdevskeletonUseCase(Number(id));
        return () => { nltdevskeletonsModel.nltdevskeleton = null; }
    }, []);

    const findCategory = (categories: any, category_id: any) => {
        for (let category of categories) {
            if (category.id === category_id) {
                return category;
            }
            if (category.children && category.children.length > 0) {
                const found = findCategory(category.children, category_id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const parent = nltdevskeleton?.category_id && findCategory(categories, nltdevskeleton.category_id);

    const options = categories?.map((item: ICategory) => ({ id: item.id, name: item.name, value: item.id }));

    const onChange = (value: string, field: string) => {
        if (nltdevskeletonsModel.nltdevskeleton) {
            nltdevskeletonsModel.nltdevskeleton = { ...nltdevskeletonsModel.nltdevskeleton, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    };

    const onSelectCategory = (selectedKeysValue: string, node: any) => {
        if (nltdevskeletonsModel.nltdevskeleton) {
            nltdevskeletonsModel.nltdevskeleton = { ...nltdevskeletonsModel.nltdevskeleton, category_id: node.value, slug: node.slug };
            setErrors((prev) => ({ ...prev, category_id: '' }));
        }
    };

    const onChangeCategory = () => {
        if (nltdevskeletonsModel.nltdevskeleton) {
            nltdevskeletonsModel.nltdevskeleton = { ...nltdevskeletonsModel.nltdevskeleton, category_id: null };
            setErrors((prev) => ({ ...prev, category_id: '' }));
        }
    };

    const onRefresh = async () => {
        await getNltdevskeletonUseCase(Number(id));
    };

    const onUpdateNltdevskeleton = async () => {
        setLoading(true);
        const result: any = nltdevskeletonSchema.safeParse({ ...nltdevskeletonsModel.nltdevskeleton });
        if (result.success && nltdevskeletonsModel.nltdevskeleton) {
            const result = await updateNltdevskeletonUseCase(nltdevskeletonsModel.nltdevskeleton as any);
            if (!result?.isError) {
                toastService.onSuccess(t('content.message.contentUpdated'));
                navigate(`/dashboard/nltdevskeletons`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    };

    const onActiveSwitch = async () => {
        if (nltdevskeletonsModel.nltdevskeleton) {
            nltdevskeletonsModel.nltdevskeleton = { ...nltdevskeletonsModel.nltdevskeleton, is_free: !nltdevskeletonsModel.nltdevskeleton.is_free };
        }
    };

    return { nltdevskeleton, parent, errors, options, onChange, onUpdateNltdevskeleton, loading, categories, onSelectCategory, onChangeCategory, onRefresh, onActiveSwitch };

}