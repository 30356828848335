import { toastService } from "src/libraries/toast"; 
import { newsModel } from "../entity/news/NewsModel";
import { newsService } from "../entity/news/NewsService";
import { GetNewsDto } from "../entity/news/dto/list-news-dto";

export const getNewsUseCase = async (body?: GetNewsDto) => {
    try {
        const response = await newsService.list(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            newsModel.news = response.data;
        }
    } catch (error) {
        console.warn('getNewsUseCase: ', error);
    }
}   