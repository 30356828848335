import { MobXRepository } from "src/repository/MobXRepository";
import { ISubscription } from "./ISubscription";

export interface ISubscriptionsModel {
    subscriptions: ISubscription[];
    subscription: ISubscription | null;
}

class SubscriptionsModel implements ISubscriptionsModel {
    private subscriptionsRepository = new MobXRepository<ISubscription[]>([]);
    private subscriptionRepository = new MobXRepository<ISubscription | null>(null);

    get subscriptions() {
        return this.subscriptionsRepository.data ?? [];
    }

    set subscriptions(data: ISubscription[]) {
        this.subscriptionsRepository.save(data);
    }

    get subscription() {
        return this.subscriptionRepository.data;
    }

    set subscription(data: ISubscription | null) {
        this.subscriptionRepository.save(data);
    }

    clear = () => {
        this.subscriptions = [];
        this.subscription = null;
    }
}

export const subscriptionsModel = new SubscriptionsModel();
