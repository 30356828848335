import React, { FC, memo } from 'react';
import { TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';

interface IProps {
    headLabels: string[];
}

export const TableHeader: FC<IProps> = memo(({ headLabels }) => {

    return (
        <TableHead>
            <TableRow>
                {headLabels.map((label, index) => (
                    <TableCell key={label + index} align={'center'}>
                        <TableSortLabel hideSortIcon>{label}</TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
})
