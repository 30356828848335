import { toastService } from "src/libraries/toast";
import { newsService } from "../entity/news/NewsService";
import { newsModel } from "../entity/news/NewsModel";

export const getNewsByIdUseCase = async (id: number | string) => {
    try {
        const response = await newsService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            newsModel.newsOne = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getNewsUseCase: ', error);
    }
}  