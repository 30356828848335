import { useEffect, useState } from "react";
import { IResources } from "src/modules/resources/entity/resources/IResources";
import { getBannerUseCase } from "../useCases/getBannerUseCase";
import { bannerModel } from "../entity/BannerModel";
import { z } from "zod";
import { useUiContext } from "src/UIProvider";
import { updateBannerUseCase } from "../useCases/updateBannerUseCase";
import { toastService } from "src/libraries/toast";

const DEFAULT_RESOURCE: any = { name: '', value: '', file: null, type: 'IMAGE', id: 0 };

export const useBannerEdit = () => {
    const { t } = useUiContext();
    const [errors, setErrors] = useState({ title: '', description: '', url_link: '', image: '' });
    const [resource, setResource] = useState<IResources>(DEFAULT_RESOURCE);
    const [loading, setLoading] = useState(false);
    const banner = bannerModel.banner;

    useEffect(() => {
        getBannerUseCase().then(() => {
            if (bannerModel.banner?.image) {
                setResource((prev) => ({ ...prev, file: bannerModel.banner?.image, type: 'IMAGE' }));
            }
        });
        return () => { bannerModel.banner = null; }
    }, []);
    
    const bannerSchema = z.object({
        title: z.string().min(1, { message: t('common.error.notEmpty') }),
        url_link: z.string().url({ message: t('common.error.invalidUrl') }).min(1, { message: t('common.error.notEmpty') }),
        image: z.any().refine(value => value !== null && value !== undefined, {
            message: t('common.error.invalidUrl'),
        }) 
    });
    
    const onChange = (value: any, field: string) => {
        if (bannerModel.banner) {
            bannerModel.banner = { ...bannerModel.banner, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
            if (field === 'type') {
                bannerModel.banner = { ...bannerModel.banner, image: null };
                setResource((prev) => ({ ...prev, file: null }));
            }
        }
    };

    const onChangeFile = (value: any, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
    };

    const onActiveSwitch = async () => {
        if (bannerModel.banner) {
            bannerModel.banner = { ...bannerModel.banner, available: !bannerModel.banner.available };
        }
    };

    const onUpdateBanner = async () => {
        setLoading(true);
        const result: any = bannerSchema.safeParse({ title: banner?.title, url_link: banner?.url_link, image: banner?.image });
        if (result.success && bannerModel.banner) {
            const body: any = {
                title: banner?.title,
                description: banner?.description,
                url_link: banner?.url_link,
                available: banner?.available,
                image: resource.file || banner?.image,
            };
            const result: any = await updateBannerUseCase(body);
            result.success && toastService.onSuccess(t('banner.message.bannerUpdated'));
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        };
        setLoading(false);
    };

    return { onUpdateBanner, banner, onChange, errors, resource, onChangeFile, onActiveSwitch, loading };
}; 