import { Store } from 'react-notifications-component';

class ToastService {

    onSuccess = (message: string) => {
        Store.addNotification({
            title:  'Success',
            message: message,
            type: 'success',
            insert: 'bottom',
            container: 'top-center',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: { duration: 3000 }
        });
    }

    onError = (message: string) => {
        Store.addNotification({
            title: "Error",
            message: message,
            type: 'danger',
            insert: 'bottom',
            container: 'top-center',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: { duration: 3000 }
        });
    }

}

export const toastService = new ToastService();