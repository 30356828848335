import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { TextInput } from 'src/UIKit/TextInput';
import { ResourceItem } from '../components/resourceItem';
import { ResourceControls } from '../components/resourceControls';
import { IResources } from '../../entity/resources/IResources';
import { useResourceEdit } from '../../presenters/useResourceEdit';

interface IProps {
    isMedia: boolean;
    item: IResources;
    length: number;
    link: string;
    entityId: number;
    close: () => void;
    onUpdate?: () => Promise<void>;
}

export const ResourcesEditView: FC<IProps> = observer(({ item, link, entityId, onUpdate, length, close, isMedia }) => {
    const { t } = useUiContext();
    const { sort_numbers, sort_number, resource, errors, onChange, TYPES, typeObject, onUpdateResource, loading } = useResourceEdit(link, entityId, close, item, length, isMedia, onUpdate);

    return (
        <Card sx={{ p: 2, my: 2 }}>
            <Select label={t('resource.resourceType')} name='type' onChange={onChange} value={typeObject?.name || ''} options={TYPES} required />
            {/* <TextInput label={t('common.name')} name='name' onChange={onChange} value={resource?.name} error={errors} /> */}
            <Stack my={.5}/>
            <Select label={t('resource.sortNumber')} name='sort_number' onChange={onChange} value={sort_number?.name || ''} options={sort_numbers} required sx={{ mb: 2 }}/>
            <ResourceItem item={resource} onChange={onChange} isMedia={isMedia}/>
            <ResourceControls onClose={close} onSave={onUpdateResource} isDisabledButton={loading}/>
        </Card>
    );
})
