import React, { FC } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { observer } from "mobx-react";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { CategoryDroppableContainer } from "../categoryDroppableContainer";

interface IProps {
    onDragEndCategory: (result) => void;
};

export const CategoryListContainer: FC<IProps> = observer(({ onDragEndCategory }) => {
    const categories = categoriesModel.categoriesTree;

    return (
        <DragDropContext onDragEnd={onDragEndCategory} onDragUpdate={() => { }} >
            <CategoryDroppableContainer level={0} categories={categories} />
        </DragDropContext>
    );
})
