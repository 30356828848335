import React, { FC } from 'react';
import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { FileDropzone } from 'src/UIKit/FileDropzone';
import { Iconify } from 'src/UIKit/Iconify';
import { Image } from 'src/UIKit/Image';
import { observer } from 'mobx-react';
import { categoriesModel } from 'src/modules/categories/entity/categories/CategoryModel';

export const CategoryImageDropZone: FC = observer(() => {
    const { t } = useUiContext();
    const category = categoriesModel.category;

    const onChangeImage = (files: File[]) => {
        if (category && files?.[0]) {
            categoriesModel.category = { ...category, image: files?.[0] as any };
        }
    };

    const omRemoveImage = () => {
        if (category) {
            categoriesModel.category ={ ...category, image: null };
        };
    };

    return (
        <Card sx={{ p: 2, mb: 2 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={3.5} md={3.5} lg={3.5}  >
                    <Typography variant='h6' mb={2} >{t('categories.categoryIcon')}</Typography>
                    {category?.image ? <Box display={'flex'} flexDirection={'row'} >
                        <Image image={category?.image} sx={{ width: 110, height: 110, borderRadius: 1.5, objectFit: 'contain' }} />
                        <IconButton sx={{ height: 50, width: 50 }} onClick={omRemoveImage}>
                            <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                    </Box> : null}
                </Grid>
                <Grid item xs={12} sm={8.5} md={8.5} lg={8.5}  >
                    <FileDropzone onDrop={onChangeImage} />
                </Grid>
            </Grid>
        </Card>
    );
});
