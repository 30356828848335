import React from "react";
// @ts-ignore
import { TreeSelect } from "antd";
import { FC, RefObject, forwardRef, useMemo } from "react";
import { Box, Typography, TypographyProps, useTheme } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from "./styles";

interface IProps {
    name: string;
    error?: { [key: string]: string };
    size?: string;
    title: string;
    value?: any | null;
    optionList: any[];
    isValid?: boolean;
    isDisabled?: boolean;
    onSelect?: (selectedKeysValue: string, node: any) => void;
    onChange?: () => void;
    typographyProps?: TypographyProps;
};

export const CheckboxAutocompleteSingle: FC<IProps> = forwardRef<RefObject<any>, IProps>(({ title, value, onSelect, optionList, onChange, size, isDisabled, name, error, typographyProps }, ref: any) => {
    const theme = useTheme();
    const categoryError = error?.[name];
    const styles = useMemo(() => getStyles(categoryError), [categoryError]);

    const transformData = (data: any[], parentKey: string) => {
        return data.map((item, index) => {
            const key = parentKey ? `${parentKey}-${index}` : `${index}`;
            const { name, children, id, slug} = item;

            const treeNode: any = {
                label: name,
                value: id,
                slug: slug
            };

            if ('children' in item) {
                treeNode.children = transformData(children, key);
            };

            return treeNode;
        });
    };

    const transformedData = transformData(optionList, '');

    const tProps = {
        treeData: transformedData,
        value,
        label: title,
        disabled: isDisabled,
        onSelect,
        onChange,
        showSearch: true,
        treeIcon: false,
        treeCheckable: false,
        placeholder: title,
        suffixIcon: !value ? <ArrowDropDownIcon sx={styles.suffixIcon} /> : null,
        allowClear: { clearIcon: <ClearIcon sx={styles.clearIcon} fontSize="small" /> },
        style: { width: '100%', height: '40px' },
        filterTreeNode: (inputValue: string, treeNode: any) =>
            treeNode.label.toLowerCase().includes(inputValue.toLowerCase()),
    };
    
    return (
        <Box sx={styles.wrapper} mb={1}>
            <Typography variant="h6" mb={1}>{title}</Typography>
            <TreeSelect ref={ref} {...tProps}/>
            <Typography minHeight={1} variant='caption' color='red' {...(typographyProps || {})}>{categoryError}</Typography>
        </Box>
    );
});
