import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { TextInput } from 'src/UIKit/TextInput';
import { useResourceCreating } from '../../presenters/useResourceCreating';
import { ResourceItem } from '../components/resourceItem';
import { ResourceControls } from '../components/resourceControls';
import { IResources } from '../../entity/resources/IResources';

interface IProps {
    isMedia: boolean;
    item?: IResources;
    link: string;
    entityId: number;
    close: () => void;
    onUpdate?: () => Promise<void>;
}

export const ResourcesCreatingView: FC<IProps> = observer(({ item, link, entityId, onUpdate, close, isMedia }) => {
    const { t } = useUiContext();
    const { resource, errors, onChange, TYPES, typeObject, onCreateResource, loading } = useResourceCreating(link, entityId, close, item, isMedia, onUpdate); 

    return (
        <Card sx={{ p: 2, mb: 2 }}>
            <Select label={t('resource.resourceType')} name='type' onChange={onChange} value={typeObject?.name || ''} options={TYPES} required/>
            {/* <TextInput label={t('common.name')} name='name' onChange={onChange} value={resource?.name} error={errors} sx={{ mb: 2 }}/> */}
            <Stack my={1}/>
            <ResourceItem item={resource} onChange={onChange} isMedia={isMedia}/>
            <ResourceControls onClose={close} onSave={onCreateResource} isDisabledButton={loading}/>
        </Card>
    );
})
