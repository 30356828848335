import { CreateNotificationDto } from "../entity/dto/create-notification-dto";
import { INotification } from "../entity/INotification";
import { notificationsService } from "../entity/NotificationService";
import { toastService } from "src/libraries/toast";

export const createNotificationUseCase = async (body: CreateNotificationDto): Promise<INotification | undefined> => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body.image) {
                if (typeof body.image === 'string') {
                    formData.append('image', body.image);
                } else if (body.image instanceof File) {
                    formData.append('image', body.image, body.image.name);
                }
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await notificationsService.createNotification(formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Notification created');
            return response.data;
        }
    } catch (error) {
        console.warn('createNotificationUseCase: ', error);
    }
}
