import { toastService } from "src/libraries/toast";
import { resourcesService } from "../entity/resources/ResourcesService";
import { UpdateResourceDto } from "../entity/resources/dto/update-resource-dto";

export const updateResourceUseCase = async (link: string, entityId: number, body: UpdateResourceDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'file' && body?.file && !body.file?.path?.includes('http')) {
                formData.append('file', body.file, body.file.name)
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await resourcesService.update(link, entityId, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
        }
        return response;
    } catch (error) {
        console.warn('updateResourceUseCase: ', error);
    }
}  