import React, { FC } from 'react';
import { Card, Container } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNews } from '../../presenters/useNews';
import { ResourcesClient } from '../components/resourcesClient';
import { NewsTop } from '../components/newsTop';

export const NewsClientView: FC = observer(() => {
    const { news } = useNews();

    return (
        <Container maxWidth="lg" sx={{ py: 4 }}>
            <Card sx={{ p: 2, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {news ? <NewsTop item={news} /> : null}
                {news?.content?.map((item) => (<ResourcesClient key={item.id} item={item} />))}
            </Card>
        </Container>
    );
})
