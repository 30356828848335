import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useNotificationDetails } from '../../presenters/useNotificationDetails';
import { observer } from 'mobx-react-lite';
import { NotificationButtons } from '../components/notificationButtons';
import { NotificationPreview } from '../components/notificationPreview';
import { NotificationAudienceInfo } from '../components/notificationAudienceInfo';

export const NotificationDetailsView: FC = observer(() => {
    const { t } = useUiContext();
    const { notification, onSend, onDelete, onGoToEditNotification } = useNotificationDetails();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('notifications.details')} />
            <NotificationAudienceInfo item={notification} />
            <NotificationButtons onSend={onSend} onDelete={onDelete} onEdit={onGoToEditNotification} />
            <NotificationPreview item={notification} />
        </Container>
    );
});
