import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";
import { INews } from "./INews";
import { GetNewsDto } from "./dto/list-news-dto";

class NewsService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    create = async (body: FormData): Promise<IResponse<INews>> => {
        try {
            const response = await this.requester.postFormData(this.links.news, body);
            return response;
        } catch (error) {
            console.warn('NewsService -> create: ', this.links.news, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    list = async (body?: GetNewsDto): Promise<IResponse<any>> => {
        try {
            const url = new URL(this.links.news);
            if (body) {
                Object.keys(body).forEach(key => url.searchParams.append(key, body[key]));
            }
            const response = await this.requester.get(url.href);
            return response;
        } catch (error) {
            console.warn('NewsService -> list: ', this.links.news, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    update = async (id: number, body: FormData): Promise<IResponse<INews>> => {
        try {
            const url = this.links.news + '/' + id;
            const response = await this.requester.patchFormData(url, body);
            return response;
        } catch (error) {
            console.warn('NewsService -> update: ', this.links.news, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    get = async (id: number | string): Promise<IResponse<INews>> => {
        try {
            const response = await this.requester.get(this.links.news + '/' + id);
            return response;
        } catch (error) {
            console.warn('NewsService -> get: ', this.links.news, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    delete = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.delete(this.links.news + id);
            return response;
        } catch (error) {
            console.warn('NewsService -> delete: ', this.links.news, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

}

export const newsService = new NewsService(requester, links);