import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getNewsUseCase } from "../useCases/getNewsUseCase";

export const useNews = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getNewsUseCase();
    }, []);

    const onGoToCreateNews = useCallback(() => {
        navigate('/dashboard/news/create');
    }, []);

    return { onGoToCreateNews };
}
