import { toastService } from "src/libraries/toast";
import { appStateModel } from "src/entities/appState/AppStateModel";
import { notificationsService } from "../entity/NotificationService";

export const deleteNotificationUseCase = async (id: number) => {
    try {
        appStateModel.isLoading = true;
        const response = await notificationsService.deleteNotification(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            return response;
        }
    } catch (error) {
        console.warn('deleteNltdevskeletonUseCase: ', error);
    } finally {
        appStateModel.isLoading = false;
    }
};   