import React, { FC } from 'react';
import { Box } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { TextInput } from 'src/UIKit/TextInput';

interface IProps {
    item: IResources;
    onChange: (value: string, field: string) => void;
}

export const ResourceMarkdown: FC<IProps> = observer(({ item, onChange }) => {
    const { t } = useUiContext();

    return (
        <TextInput
            name="value"
            label={t('markdown')}
            value={item.value}
            onChange={onChange}
            textFieldProps={{
                multiline: true,
                minRows: 10,
                fullWidth: true
            }}
        />
    );
})
