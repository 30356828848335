import React, { FC } from 'react';
import { Card, Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { useCategories } from '../../presenters/useCategories';
import { CategoryListContainer } from '../components/categoryListContainer';

export const CategoriesView: FC = observer(() => {
    const { t } = useUiContext();
    const { search, onDragEndCategory, onSearch, onGoToCreateCategory } = useCategories();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('categories.categories')} onPress={onGoToCreateCategory} view='categoryList' />
            <Card>
                {/* <ListToolbar search={search} onSearch={onSearch} placeholder={t('categories')} /> */}
                <Scrollbar >
                    <CategoryListContainer onDragEndCategory={onDragEndCategory} />
                </Scrollbar>
            </Card>
        </Container>
    );
})
