import { userModel } from "src/entities/user/UserModel";
import { userService } from "src/entities/user/UserService";
import { UserSignInDto } from "src/entities/user/dto/user-sign-in.dto";
import { SHA256 } from "src/libraries/sha256";
import { toastService } from "src/libraries/toast";

export const authorizationUseCase = async ({ email, password }: UserSignInDto) => {
    try {
        // const hashPassword = SHA256(password);
        const response = await userService.signIn({ email, password });
        if (response?.isError) {
            // toastService.onError('Some error');
        } else {
            userModel.token = response.data.access_token;
        }
        return response;
    } catch (error) {
        console.warn('authorizationUseCase: ', error);
        return { isError: true, message: '', data: null };
    }
}