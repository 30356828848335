import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { INotification } from "./INotification";
import { INotificationDetails } from "./INotificationDetails";
import { ILinks, links } from "src/Links";
import { GetNotificationsDto } from "./dto/get-notifiications-dto";

class NotificationsService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    getNotifications = async (body?: GetNotificationsDto): Promise<IResponse<any>> => {
        try {
            const url = new URL(this.links.notifications);
            if (body) {
                Object.keys(body).forEach(key => url.searchParams.append(key, body[key]));
            }
            const response = await this.requester.get(url.href);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> getNotifications: ', this.links.notifications, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    deleteNotification = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.delete(this.links.notifications + `/${id}`);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> deleteNotification: ', this.links.notifications, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    createNotification = async (body: FormData): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.postFormData(this.links.notifications, body);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> createNotification: ', this.links.notifications, body, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    getNotification = async (id: number): Promise<IResponse<INotificationDetails>> => {
        try {
            const response = await this.requester.get(`${this.links.notifications}/${id}`);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> getNotification: ', this.links.notifications, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    uploadNotification = async (id: string | undefined, body: FormData) => {
        try {
            const response = await this.requester.patchFormData(this.links.notifications + `/${id}`, body);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> uploadNotification: ', this.links.notifications, body, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    sendNotification = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.post(this.links.notifications + `/${id}/send`);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> sendNotification: ', this.links.notifications, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    sendContentNotification = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.post(this.links.products + `/${id}/send-notification`);
            return response;
        } catch (error) {
            console.warn('NotificationsService -> sendContentNotification: ', this.links.products, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };
}

export const notificationsService = new NotificationsService(requester, links);
