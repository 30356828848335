import React, { FC } from 'react';
import { Box, Container, Stack } from '@mui/material';
import { AuthForm } from '../components/authForm';
import { StyledContent, LogoContainer, AnimatedImage, AuthFormContainer, ViewWrapper } from './styles';
import { observer } from 'mobx-react';
import { useAuthorization } from '../../presenters/useAuthorization';

export const AuthorizationView: FC = observer(() => {
    const { showNewComponent } = useAuthorization();

    return (
        <ViewWrapper>
            <StyledContent maxWidth="sm">
                <LogoContainer show={showNewComponent} spacing={2}>
                    <AnimatedImage src="/assets/logo.svg" width={440} height={86} alt="Logo" />
                </LogoContainer>
                <AuthFormContainer show={showNewComponent}>
                    <AuthForm />  
                </AuthFormContainer>
            </StyledContent>
        </ViewWrapper>
    );
})
