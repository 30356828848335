import { MobXRepository } from "../../../../repository/MobXRepository"
import { INews } from "./INews";

export interface INewsModel {
    news: { count: number; rows: INews[] } | null;
    newsOne: INews | null;
}

class NewsModel implements INewsModel {
    private newsRepository = new MobXRepository<{ count: number; rows: INews[] } | null>(null);
    private newsOneRepository = new MobXRepository<INews | null>(null);

    get news() {
        return this.newsRepository.data;
    }

    set news(data: { count: number; rows: INews[] } | null) {
        this.newsRepository.save(data);
    }

    get newsOne() {
        return this.newsOneRepository.data;
    }

    set newsOne(data: INews | null) {
        this.newsOneRepository.save(data);
    }

    clear = () => {
        this.news = null;
        this.newsOne = null;
    }

}

export const newsModel = new NewsModel();
