import React, { FC } from 'react';
import { Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { VacancyTableRow } from '../tableRow';
import { useVacanciesTable } from '../../../presenters/useVacanciesTable';
import { vacanciesModel } from '../../../entity/vacancies/VacanciesModel';

const TABLE_HEAD = ['Name', 'Salary', 'Application deadline', 'Status', 'Available', ''];

export const VacanciesTable: FC = observer(() => {
    const { t } = useUiContext();
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useVacanciesTable();

    return (
        <Card>˝
            <ListToolbar search={search} onSearch={onSearch} placeholder={t('vacancies.vacancies')} />
            <Scrollbar >
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHeader headLabels={TABLE_HEAD} />
                        <TableBody>
                            {vacanciesModel.list?.rows?.map((item) => (<VacancyTableRow item={item} key={item.id} />))}
                        </TableBody>
                        {/* <TableEmptyList /> */}
                    </Table>
                </TableContainer>
            </Scrollbar>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={vacanciesModel.list?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </Card>
    );
})
