import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createFeaturesUseCase } from "../useCases/createFeaturesUseCase";
import { z } from 'zod';
import { featuresModel } from "../entity/features/FeaturesModel";

const errorsSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    description: z.string().nullable(),
    development_side: z.string().nullable(),
    slug: z.string().min(1, { message: "Not empty" }),
    available: z.boolean(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }).nullable(),
});

const DEVELOPMENT_SIDES = [{ name: 'Front-end', id: 'Front-end' }, { name: 'Back-end', id: 'Back-end' }, { name: 'Full-stack', id: 'Full-stack' }, { name: 'Mobile', id: 'Mobile' }];

export const useFeaturesCreating = () => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '', estimate: '', estimate_market: '', price: '', development_side: '' });
    const features = featuresModel.feature;
    const navigate = useNavigate();

    useEffect(() => {
        featuresModel.feature = { name: '', description: '', slug: '', available: false, image: null, estimate: 0, estimate_market: 0, price: 0, development_side: '' } as any;
        return () => { featuresModel.feature = null; }
    }, []);

    const onChange = (value: string, field: string) => {
        if (featuresModel.feature) {
            featuresModel.feature = { ...featuresModel.feature, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onCreateFeatures = async () => {
        setLoading(true);
        const result: any = errorsSchema.safeParse({ ...featuresModel.feature });
        if (result.success && featuresModel.feature) {
            const result = await createFeaturesUseCase(featuresModel.feature as any);
            if (!result?.isError) {
                navigate(`/dashboard/features/edit/${result?.data.id}`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    return { DEVELOPMENT_SIDES, features, errors, onChange, onCreateFeatures };

}