import React, { FC } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { observer } from 'mobx-react';
import { appStateModel } from 'src/entities/appState/AppStateModel';

export const Loader: FC = observer(() => {
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme: any) => theme.zIndex.drawer + 1 }} open={appStateModel.isLoading}  >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
})
