import { IUser } from "src/entities/user/IUser";
import { IRequester, requester } from "src/libraries/requester";
import { ILinks, links } from "src/Links";
import { GetUsersDto } from "./dto/list-users-dto";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";

class UserService {
    constructor(
      private requester: IRequester,
      private links: ILinks,
    ) { };
  
    getList = async (body?: GetUsersDto): Promise<IResponse<{ count: number; rows: IUser[] }>> => {
      try {
        const url = new URL(this.links.users);
        if (body) {
          Object.keys(body).forEach(key => url.searchParams.append(key, body[key]));
        }
        const response = await this.requester.get(url.href);
        return response;
      } catch (error) {
        console.warn('UserService -> getList: ', this.links.users, error);
        return { isError: true, data: null, message: '' } as any;
      }
    }
  
    get = async (id: number | string): Promise<IResponse<IUser>> => {
      try {
        const response = await this.requester.get(this.links.users + '/' + id);
        return response;
      } catch (error) {
        console.warn('UserService -> get: ', this.links.users, error);
        return { isError: true, data: null, message: '' } as any;
      }
    }
  
    update = async (id: number, body: FormData): Promise<IResponse<any>> => {
      try {
        const url = this.links.users + '/' + id;
        const response = await this.requester.patchFormData(url, body);
        return response;
      } catch (error) {
        console.warn('UserService -> update: ', this.links.users, error);
        return { isError: true, data: null, message: '' } as any;
      }
    }
  
    delete = async (id: number): Promise<IResponse<any>> => {
      try {
        const response = await this.requester.delete(this.links.users + `/${id}`);
        return response;
      } catch (error) {
        console.warn('UserService -> delete: ', this.links.users, error);
        return { isError: true, data: null, message: '' } as any;
      }
    }
  };
  
  export const userService = new UserService(requester, links);