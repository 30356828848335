import React, { FC } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { styles } from './styles';
import { INotification } from 'src/modules/notifications/entity/INotification';
import dayjs from 'dayjs';
import { DetailData } from '../detailData';

interface IProps {
    item: INotification | null;
};

export const NotificationAudienceInfo: FC<IProps> = observer(({ item }) => {
    const { t } = useUiContext();

    const time = dayjs(item?.audience?.time).format('DD-MM-YYYY HH:mm');

    return (
        <Card sx={styles.container}>
            <Box component="img" sx={styles.image} alt="The notification image" src={item?.image?.path || '/assets/default_image.jpg'} />
            <Box sx={{ px: 2, overflow: 'hidden' }} >
                <DetailData title={t('notifications.header')} value={item?.title}/>
                <DetailData title={t('notifications.content')} value={item?.body}/>
                {item?.navigation_url && <DetailData title={t('notifications.link')} value={item?.navigation_url}/>}
                {item?.image?.path && <DetailData title={t('notifications.imageUrl')} value={item?.image.path}/>}
                <DetailData title={t('notifications.status')} value={item?.status}/>
                {item?.audience?.time && <DetailData title={t('notifications.time')} value={time}/>}
            </Box>
        </Card>
    );
});
