import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useResources = () => {
    const [visible, setVisible] = useState(false);

    const onOpen = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return { visible, onOpen, onClose };
}
