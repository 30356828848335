import React, { FC } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';

interface IProps {
    onSend: () => void;
    onDelete: () => void;
    onEdit: () => void;
}

export const NotificationButtons: FC<IProps> = observer(({ onSend, onDelete, onEdit }) => {
    const { t } = useUiContext();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', my: 2 }}>
            <ButtonGroup variant="contained" size='large' >
                <Button onClick={onSend} >{t('common.send')}</Button>
                <Button onClick={onEdit}>{t('common.edit')}</Button>
                <Button onClick={onDelete} >{t('common.delete')}</Button>
            </ButtonGroup>
        </Box>
    );
});
