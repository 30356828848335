import React, { FC } from 'react';
import { Grid, TablePagination } from '@mui/material';
import { NewsCard } from '../newsCard';
import { observer } from 'mobx-react';
import { newsModel } from 'src/modules/news/entity/news/NewsModel';
import { useNewsTable } from 'src/modules/news/presenters/useNewsTable';

export const NewsList: FC = observer(() => {
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useNewsTable();

    return (
        <>
            <Grid container spacing={3} >
                {newsModel.news?.rows?.map((item) => (
                    <Grid key={item.id} item xs={12} sm={6} md={3} lg={2.4}>
                        <NewsCard item={item} />
                    </Grid>
                ))}
            </Grid>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={newsModel.news?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </>
    );
})
