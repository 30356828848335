import { TTheme } from "./IColors";
import { COLORS } from './Colors';
import { MobXRepository } from "../../repository/MobXRepository";
import { IRepository } from "../../repository/IRepository";

class ColorTheme {
    constructor(
        private themeStore: IRepository<TTheme>,
    ) { }

    get theme() {
        return this.themeStore.data || 'dark';
    }

    get colors() {
        // @ts-ignore
        return COLORS[this.theme] || COLORS.dark;
    }

    setTheme = (data: TTheme) => {
        this.themeStore.save(data);
    }

}

const themeStore = new MobXRepository<TTheme>('dark');
export const colorTheme = new ColorTheme(themeStore);
