import { MobXRepository } from "src/repository/MobXRepository";

class AppStateModel {
    private isLoadingRepository = new MobXRepository<boolean>(false);

    get isLoading() {
        return this.isLoadingRepository.data ?? false;
    }

    set isLoading(data: boolean) {
        this.isLoadingRepository.save(data);
    }

    clear = () => {
        this.isLoading = false;
    }

}

export const appStateModel = new AppStateModel();