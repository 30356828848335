import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";
import { GetCategoriesDto } from "./dto/list-categories-dto";
import { ICategory } from "./ICategory";

class CategoriesService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }


    create = async (body: FormData): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.postFormData(this.links.categories, body);
            return response;
        } catch (error) {
            console.warn('CategoriesService -> create: ', this.links.categories, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    list = async (body?: GetCategoriesDto): Promise<IResponse<any>> => {
        try {
            const url = body?.tree ? this.links.categories + '?tree=true' : this.links.categories;
            const response = await this.requester.get(url);
            return response;
        } catch (error) {
            console.warn('CategoriesService -> list: ', this.links.categories, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    update = async (id: number, body: FormData) => {
        try {
            const url = this.links.categories + '/' + id;
            const response = await this.requester.patchFormData(url, body);
            return response;
        } catch (error) {
            console.warn('CategoriesService -> update: ', this.links.categories, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    get = async (id: number | string): Promise<IResponse<ICategory>> => {
        try {
            const response = await this.requester.get(this.links.categories + '/' + id);
            return response;
        } catch (error) {
            console.warn('CategoriesService -> get: ', this.links.categories, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    delete = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.delete(this.links.categories + id);
            return response;
        } catch (error) {
            console.warn('CategoriesService -> delete: ', this.links.categories, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

};

export const categoriesService = new CategoriesService(requester, links);