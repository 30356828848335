import { useState } from "react";
import { IResources } from "../entity/resources/IResources";
import { useNavigate } from "react-router-dom";
import { updateResourceUseCase } from "../useCases/updateResourceUseCase";
import { deleteResourceUseCase } from "../useCases/deleteResourceUseCase";
import { useUiContext } from "src/UIProvider";
import { toastService } from "src/libraries/toast";

export const useResourcesItem = (item: IResources, link?: string, entityId?: number, onUpdate?: () => Promise<void>) => {
    const { t } = useUiContext();
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();

    const onEditOpen = () => {
        setEdit(!edit);
    };

    const onEditClose = () => {
        setEdit(false);
    };

    const onDelete = async () => {
        if (link && entityId) {
            const result: any = await deleteResourceUseCase(link, entityId, item.id);
            if (!result?.isError) {
                toastService.onSuccess(t('resource.message.resourceRemoved'));
            }; 
            await onUpdate?.();
        }
    };

    return { edit, onEditOpen, onEditClose, onDelete }
}