import { notificationsModel } from "../entity/NotificationModel";
import { toastService } from "src/libraries/toast";
import { notificationsService } from "../entity/NotificationService";

export const getNotificationUseCase = async (id: number) => {
    try {
        const response = await notificationsService.getNotification(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            notificationsModel.notification = response.data;
        }
    } catch (error) {
        console.warn('getNotificationUseCase: ', error);
    }
}