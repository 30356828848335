import React, { FC } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { observer } from "mobx-react";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { CategoryDroppableContainer } from "../categoryDroppableContainer";
import { newsCategoryModel } from "src/modules/newsCategories/entity/newsCategories/NewsCategoryModel";

interface IProps {
    onDragEndCategory: (result) => void;
}

export const CategoryListContainer: FC<IProps> = observer(({ onDragEndCategory }) => {
    const categories = newsCategoryModel.newsCategories;

    return (
        <DragDropContext onDragEnd={onDragEndCategory} onDragUpdate={() => { }} >
            <CategoryDroppableContainer categories={categories} />
        </DragDropContext>
    );
})
