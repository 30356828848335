import React, { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { DashboardLayout } from '../layouts/dashboard';
import { AuthorizationView } from '../modules/authentication/ui/Authorization';
import Page404 from '../modules/Page404';
import { CategoriesView } from 'src/modules/categories/ui/Categories';
import { CategoryCreatingView } from 'src/modules/categories/ui/CategoryCreating';
import { CategoryEditingView } from 'src/modules/categories/ui/CategoryEditing';
import { NltdevskeletonsView } from 'src/modules/nltdevskeletons/ui/Nltdevskeletons';
import { NltdevskeletonCreatingView } from 'src/modules/nltdevskeletons/ui/NltdevskeletonCreating';
import { NltdevskeletonEditingView } from 'src/modules/nltdevskeletons/ui/NltdevskeletonEditing';
import { Outlet } from 'react-router-dom';
import { NewsView } from 'src/modules/news/ui/News';
import { NewsCreatingView } from 'src/modules/news/ui/NewsCreating';
import { NewsEditingView } from 'src/modules/news/ui/NewsEditing';
import { FeaturesView } from 'src/modules/features/ui/Features';
import { FeaturesCreatingView } from 'src/modules/features/ui/FeaturesCreating';
import { FeaturesEditingView } from 'src/modules/features/ui/FeaturesEditing';
import { NewsCategoriesView } from 'src/modules/newsCategories/ui/NewsCategories';
import { NewsCategoryCreatingView } from 'src/modules/newsCategories/ui/NewsCategoryCreating';
import { NewsCategoryEditingView } from 'src/modules/newsCategories/ui/NewsCategoryEditing';
import { NewsHomeView } from 'src/modules-client/news/ui/news-home';
import { VacanciesView } from 'src/modules/hr-department/vacancies/ui/Vacancies';
import { VacancyCreatingView } from 'src/modules/hr-department/vacancies/ui/VacancyCreating';
import { VacancyEditingView } from 'src/modules/hr-department/vacancies/ui/VacancyEditing';
import { SubscriptionsView } from 'src/modules/hr-department/subscription/ui/Subscriptions';
import { NewsCategoryView } from 'src/modules-client/news/ui/news-category';
import { NewsClientView } from 'src/modules-client/news/ui/news';
import { NewsLayout } from 'src/modules-client/news/ui/layouts';
import { userModel } from 'src/entities/user/UserModel';
import { NotificationsListView } from 'src/modules/notifications/ui/Notifications';
import { NotificationCreationView } from 'src/modules/notifications/ui/NotificationCreation';
import { NotificationEditView } from 'src/modules/notifications/ui/NotificationEdit';
import { NotificationDetailsView } from 'src/modules/notifications/ui/NotificationDetails';
import PageSuccessPayment from 'src/modules/PageSuccessPayment';
import PageErrorPayment from 'src/modules/PageErrorPayment';
import { BannerEditView } from 'src/modules/banners/ui/BannerEdit';

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const token = userModel.token;
  return token ? children : <Navigate to="/login" />;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const token = userModel.token;
  return token ? <Navigate to="/dashboard/categories" /> : children;
};

export const Router: FC = () => {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute><DashboardLayout /></PrivateRoute>,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },

        { path: 'categories', element: <CategoriesView />, },
        { path: 'categories/create', element: <CategoryCreatingView />, },
        { path: 'categories/edit/:id', element: <CategoryEditingView />, }, 

        { path: 'notifications', element: <NotificationsListView />, },
        { path: 'notifications/create', element: <NotificationCreationView />, },
        { path: 'notifications/edit/:id', element: <NotificationEditView />, },
        { path: 'notifications/details/:id', element: <NotificationDetailsView />, },

        { path: 'banners', element: <BannerEditView />, },

        { path: 'nltdevskeletons', element: <NltdevskeletonsView />, },
        { path: 'nltdevskeletons/create', element: <NltdevskeletonCreatingView />, },
        { path: 'nltdevskeletons/edit/:id', element: <NltdevskeletonEditingView />, },

        { path: 'news', element: <NewsView />, },
        { path: 'news/create', element: <NewsCreatingView />, },
        { path: 'news/edit/:id', element: <NewsEditingView />, },

        { path: 'news-categories', element: <NewsCategoriesView />, },
        { path: 'news-categories/create', element: <NewsCategoryCreatingView />, },
        { path: 'news-categories/edit/:id', element: <NewsCategoryEditingView />, },

        { path: 'features', element: <FeaturesView />, },
        { path: 'features/create', element: <FeaturesCreatingView />, },
        { path: 'features/edit/:id', element: <FeaturesEditingView />, },

        { path: 'product', element: <SubscriptionsView />, }, 

        { path: 'vacancies', element: <VacanciesView />, },
        { path: 'vacancies/create', element: <VacancyCreatingView />, },
        { path: 'vacancies/edit/:id', element: <VacancyEditingView />, },

      ],
    },
    { path: 'login', element: <PublicRoute><AuthorizationView /></PublicRoute>, },
    {
      path: '/news',
      element: <NewsLayout />,
      children: [
        { element: <Navigate to="/news/home" />, index: true },
        { path: 'home', element: <NewsHomeView />, },
        { path: 'category/:slug', element: <NewsCategoryView />, },
        { path: 'articles/:slug', element: <NewsClientView />, },
      ]
    },
    {
      element: <Outlet />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: 'success', element: <PageSuccessPayment /> },
        { path: 'failure', element: <PageErrorPayment /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
};
