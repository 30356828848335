import { useRef, useState } from "react";

export const useTable = () => {
    const [search, setSearch] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [page, setPage] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null);

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setPage(0);
        scrollTopList();
    }

    const onChangePage = (event, newPage) => {
        setPage(newPage - 1);
        scrollTopList();
    };

    const onChangeRowsPerPage = (event) => {
        setPage(1);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage, scrollRef };
}