import { toastService } from "src/libraries/toast";
import { newsCategoryService } from "../entity/newsCategories/NewsCategoryService";
import { newsCategoryModel } from "../entity/newsCategories/NewsCategoryModel";

export const getNewsCategoriesUseCase = async () => {
    try {
        const response = await newsCategoryService.list();
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            newsCategoryModel.newsCategories = response.data;
        }
    } catch (error) {
        console.warn('getNewsCategoriesUseCase: ', error);
    }
}   