import { Box, Container, Stack, StackProps, keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledContent = styled(Container)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export const ViewWrapper = styled(Box)`
  background-color: #F5F5F5;
`;

const appearAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; visibility: visible; }
`;

export const LogoContainer = styled(Stack) <{ show: boolean | undefined }>`
  align-items: center;
  opacity: 1;
  zIndex: 1000;
  animation: ${props => props.show ? fadeOut : 'none'} .1s ease-out forwards;
`;

// export const LogoContainer = styled(Stack, {
//   shouldForwardProp: (prop) => prop !== 'show'
// })<{ show: boolean | undefined }>`
//   align-items: center;
//   opacity: 1;
//   z-index: 1000;
//   animation: ${props => props.show ? fadeOut : 'none'} .1s ease-out forwards;
// `;

export const AnimatedImage = styled('img')`
  position: absolute;
  animation: ${appearAnimation} 1.7s ease forwards;
`;

export const AuthFormContainer = styled(Stack) <{ show: boolean | undefined }>`
  maxWidth: 480,
  position: absolute;
  visibility: hidden;
  zindex: 800;
  opacity: 0;
  animation: ${props => props.show ? fadeIn : 'none'} 1.2s ease-in forwards;
`;

// export const AuthFormContainer = styled(Stack, {
//   shouldForwardProp: (prop) => prop !== 'show'
// })<{ show: boolean | undefined }>`
//   maxWidth: 480px;
//   position: absolute;
//   visibility: hidden;
//   z-index: 800;
//   opacity: 0;
//   animation: ${props => props.show ? fadeIn : 'none'} 1.2s ease-in forwards;
// `;