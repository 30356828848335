import { toastService } from "src/libraries/toast";
import { featuresService } from "../entity/features/FeaturesService";
import { featuresModel } from "../entity/features/FeaturesModel";

export const getFeaturesByIdUseCase = async (id: number | string) => {
    try {
        const response = await featuresService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            featuresModel.feature = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getFeaturesUseCase: ', error);
    }
}  