import React, { FC } from 'react';
import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { observer } from 'mobx-react';
import { userModel } from 'src/entities/user/UserModel';
import { useNavigate, useNavigation } from 'react-router-dom';
import { AccountIconButton, CustomPopover } from './accountPopover/styles';
import { useUiContext } from 'src/UIProvider';



// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

export const AccountPopover: FC = observer(() => {
  const { t } = useUiContext();
  const [open, setOpen] = useState(null);
  const navigate = useNavigate();

  const localStorageUser = localStorage.getItem('user');
  const user = localStorageUser? JSON.parse(localStorageUser) : null;

  const account = {
    displayName: user?.full_name || t('user.fullName'),
    email: user?.email || 'demo@minimals.cc',
    photoURL: user?.image?.path || '/assets/icons/user.svg'
  };
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogOut = () => {
    setOpen(null);
    userModel.clear();
    navigate(`/login`);
  };

  return (
    <>
      <AccountIconButton open={Boolean(open)} onClick={handleOpen}>
        <Avatar src={account.photoURL} alt="photoURL" />
      </AccountIconButton>

      <CustomPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{width: 'auto'}}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}> 
          <Typography variant="subtitle2" noWrap>
            {account.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {account.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack> */}

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogOut} sx={{ m: 1 }}>
          {t('auth.signOut')}
        </MenuItem>
      </CustomPopover>
    </>
  );
});
