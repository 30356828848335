import React, { FC } from 'react';
import { Card, Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { useNltdevskeletonEditing } from '../../presenters/useNltdevskeletonEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { NltdevskeletonImageDropZone } from '../components/categoryImageDropZone';
import { CheckboxAutocompleteSingle } from 'src/modules/categories/ui/components/autocompleteTree';
import { ResourcesView } from 'src/modules/resources/ui/Resources';
import { links } from 'src/Links';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';

export const NltdevskeletonEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { nltdevskeleton, parent, errors, options, onChange, onUpdateNltdevskeleton, loading, categories, onSelectCategory, onChangeCategory, onRefresh, onActiveSwitch } = useNltdevskeletonEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('content.editContent')} buttonTitle={t('save')} onPress={onUpdateNltdevskeleton} isDisabledButton={loading} />
            <NltdevskeletonImageDropZone />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('common.name')} name='name' onChange={onChange} value={nltdevskeleton?.name} error={errors} required />
                {/* <TextInput label={t('common.description')} name='description' onChange={onChange} value={nltdevskeleton?.description} /> */}
                {/* <TextInput label={t('common.slug')} name='slug' onChange={onChange} value={nltdevskeleton?.slug} error={errors} required /> */}
                {/* <Select label={t('content.parentCategory') + '*'} name='parent_id' onChange={onChange} value={parent?.name || ''} options={options} /> */}
                <CheckboxAutocompleteSingle name='category_id' title={t('content.parentCategory') + '*'} optionList={categories} onSelect={onSelectCategory} value={parent?.id} onChange={onChangeCategory} error={errors} isDisabled/>
                {nltdevskeleton && <IOSSwitch label={t('common.free')} secondLabel={t('common.noFree')} name="free" onChange={onActiveSwitch} checked={nltdevskeleton?.is_free} />}
            </Card>
            <ResourcesView resources={nltdevskeleton?.content || []} link={links.nltdevskeletons} entityId={nltdevskeleton?.id} onUpdate={onRefresh} slug={nltdevskeleton?.slug}/>
        </Container> 
    );
});
