import { toastService } from "src/libraries/toast";
import { subscriptionsService } from "../entity/subscriptions/SubscriptionsService";

export const updateSubscriptionUseCase = async (payload: {id: number, price: number}) => {
    try {
        const response = await subscriptionsService.update(payload);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            return response.data;
        }
    } catch (error) {
        console.warn('updateSubscriptionUseCase: ', error);
    };
};
