import { toastService } from "src/libraries/toast";
import { vacanciesService } from "../entity/vacancies/VacanciesService";
import { vacanciesModel } from "../entity/vacancies/VacanciesModel";

export const getVacancyUseCase = async (id: number | string) => {
    try {
        const response = await vacanciesService.get(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            vacanciesModel.current = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getVacancyUseCase: ', error);
    }
}  