import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from 'zod';
import { getVacancyUseCase } from "../useCases/getVacancyUseCase";
import { updateNewsUseCase } from "../useCases/updateVacancyUseCase";
import { vacanciesModel } from "../entity/vacancies/VacanciesModel";

const errorsSchema = z.object({

});

export const useVacanciesEditing = () => {
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '', category_id: '' });
    const vacancy = vacanciesModel.current;
    const navigate = useNavigate();

    useEffect(() => {
        getVacancyUseCase(Number(id));
        return () => { vacanciesModel.current = null; }
    }, []);

    const onChange = (value: any, field: string) => {
        if (vacanciesModel.current) {
            vacanciesModel.current = { ...vacanciesModel.current, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onUpdate = async () => {
        setLoading(true);
        const result: any = errorsSchema.safeParse({ ...vacanciesModel.current });
        if (result.success && vacanciesModel.current) {
            await updateNewsUseCase(vacanciesModel.current as any);
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    const onRefresh = async () => {
        await getVacancyUseCase(Number(id));
    }

    return { vacancy, errors, onChange, onUpdate, onRefresh };

}