import React, { FC } from 'react';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dark, materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'

interface IProps {
    item: IResources;
}

export const PreviewMarkdown: FC<IProps> = ({ item }) => {

    return (<Markdown remarkPlugins={[remarkGfm]}
        components={{
            code(props) {
                const { children, className, node, ...rest } = props
                const match = /language-(\w+)/.exec(className || '')
                return match ? (
                    <SyntaxHighlighter
                        {...rest}
                        PreTag="div"
                        children={String(children).replace(/\n$/, '')}
                        language={'javascript'}
                        style={materialDark}
                    />
                ) : (
                    <code {...rest} className={className}>
                        {children}
                    </code>
                )
            }
        }}
    >{item.value}</Markdown>)
}
