import { IUser } from "src/entities/user/IUser";
import { MobXRepository } from "src/repository/MobXRepository";

export interface IUserModel {
    users: { count: number; rows: IUser[] } | null;
    user: IUser | null;
};

class UserModel implements IUserModel {
    private usersRepository = new MobXRepository<{ count: number; rows: IUser[] } | null>(null);
    private userRepository = new MobXRepository<IUser | null>(null);

    constructor() {
        const savedUser = localStorage.getItem('user');
        if (savedUser) {
            this.userRepository.save(JSON.parse(savedUser));
        }
    };

    get users() {
        return this.usersRepository.data;
    };

    set users(data: { count: number; rows: IUser[] } | null) {
        this.usersRepository.save(data);
    };

    get user() {
        return this.userRepository.data;
    };

    set user(data: IUser | null) {
        if (data) {
            localStorage.setItem('user', JSON.stringify(data));
        }
        this.userRepository.save(data);
    };

    clear = () => {
        this.users = null;
        this.user = null;
        localStorage.removeItem('user');
    };
};

export const userModel = new UserModel();
