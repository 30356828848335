import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNewsCategoriesUseCase } from "../useCases/getNewsCategoriesUseCase";
import { updateNewsCategoryUseCase } from "../useCases/updateNewsCategoryUseCase";
import { newsCategoryModel } from "../entity/newsCategories/NewsCategoryModel";

export const useNewsCategories = () => {
    const [search, setSearch] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getNewsCategoriesUseCase();
    }, []);

    const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onGoToCreateNewsCategory = useCallback(() => {
        navigate('/dashboard/news-categories/create');
    }, []);

    const onDragEndNewsCategory = useCallback(async (result) => {
        if (!result.destination || result.destination.index === result.source.index) {
            return;
        }
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        let reorderCategories = [...newsCategoryModel.newsCategories];
        let orders: { id: number, sortNumber: number }[] = [];
        const [removed] = reorderCategories.splice(sourceIndex, 1);
        reorderCategories.splice(destinationIndex, 0, removed);
        newsCategoryModel.newsCategories = reorderCategories;
        orders = reorderCategories.map((category, index) => ({ id: category.id, sortNumber: index + 1 }));
        newsCategoryModel.newsCategories = reorderCategories;
        await updateNewsCategoryUseCase({ id: Number(result.draggableId), sort_number: destinationIndex + 1 });
    }, []);

    return { search, onDragEndNewsCategory, onSearch, onGoToCreateNewsCategory };
}
