import { notificationsService } from "../entity/NotificationService";
import { toastService } from "src/libraries/toast";

export const sendContentNotificationUseCase = async (id: number) => {
    try {
        const response = await notificationsService.sendContentNotification(id);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {  
            return response;
        }
    } catch (error) {
        console.warn('getNotificationUseCase: ', error);
    }
};