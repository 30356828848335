import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { observer } from 'mobx-react-lite';
import { useVacanciesEditing } from '../../presenters/useVacanciesEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { IOSSwitch } from 'src/UIKit/IOSSwitch';

export const VacancyEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { vacancy, errors, onChange, onUpdate } = useVacanciesEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('vacancies.editVacancy')} buttonTitle={t('save')} onPress={onUpdate} />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('name')} name='name' onChange={onChange} value={vacancy?.name} error={errors} required />
                <TextInput label={t('description')} name='description' onChange={onChange} value={vacancy?.description} />
                <TextInput
                    label={t('requirements')}
                    name='requirements'
                    onChange={onChange}
                    value={vacancy?.requirements}
                    textFieldProps={{
                        multiline: true,
                        minRows: 5,
                        fullWidth: true
                    }} />
                <TextInput
                    label={t('benefits')}
                    name='benefits'
                    onChange={onChange}
                    value={vacancy?.benefits}
                    textFieldProps={{
                        multiline: true,
                        minRows: 5,
                        fullWidth: true
                    }} />
                <TextInput label={t('salary')} name='salary' onChange={onChange} value={vacancy?.salary} />
                <IOSSwitch label={t('active')} name="available" onChange={onChange} checked={vacancy?.available} />
            </Card>
        </Container>
    );
})
