import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useFeatures } from '../../presenters/useFeatures';
import { FeaturesTable } from '../components/table';

export const FeaturesView: FC = observer(() => {
    const { t } = useUiContext();
    const { onGoToCreateFeatures } = useFeatures();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('features')} buttonTitle={t('createFeatures')} onPress={onGoToCreateFeatures} />
            <FeaturesTable />
        </Container>
    );
})
