import { IRequester, requester } from "src/libraries/requester";
import { ILinks, links } from "../../Links"; 
import { IUser } from "./IUser";
import { userModel } from "./UserModel";
import { UserSignInDto } from "./dto/user-sign-in.dto";
import { UserSignUpDto } from "./dto/user-sign-up.dto";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";

class UserService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    signIn = async (body: UserSignInDto): Promise<IResponse<{ user: IUser; access_token: string; }>> => {
        try { 
            const response = await this.requester.post(this.links.signIn, body);
            return response;
        } catch (error) {
            console.warn('UserService -> signIn: ', error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    profile = async (): Promise<IResponse<IUser>> => {
        try {
            const response = await this.requester.get(this.links.users + '/me');
            if (!response.isError && response.data) {
                userModel.user = response.data;
            }
            return response;
        } catch (error) {
            console.warn('UserService -> getUser: ', error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    update = async (formData: FormData): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.postFormData(this.links.users, formData);
            if (!response.isError && response.data) {
                userModel.user = response.data;
            }
            return response;
        } catch (error) {
            console.error('MetadialogService -> create: ', error);
            return null as any;
        }
    }

}

export const userService = new UserService(requester, links);
