import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IResources } from 'src/modules/resources/entity/resources/IResources';

interface IProps {
    item: IResources;
}

export const PreviewAudio: FC<IProps> = ({ item }) => {

    return (
        <Box sx={{ height: '80px', display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <audio controls src={item.file?.path}></audio>
        </Box>
    )
}
