import { toastService } from "src/libraries/toast";
import { notificationsService } from "../entity/NotificationService";
import { notificationsModel } from "../entity/NotificationModel";
import { GetNotificationsDto } from "../entity/dto/get-notifiications-dto";

export const getNotificationsUseCase = async (body: GetNotificationsDto) => {
    try {
        const response = await notificationsService.getNotifications(body);
        if (response?.isError) {            
            toastService.onError('Some error');
        } else {
            notificationsModel.notifications = response.data;
        }
        return response;
    } catch (error) {
        console.warn('getNotificationsUseCase: ', error);
    }
};  