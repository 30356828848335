import React, { FC } from 'react';
import { Card, Pagination, Paper, Stack, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { useNltdevskeletonsTable } from 'src/modules/nltdevskeletons/presenters/useNltdevskeletonsTable';
import { nltdevskeletonsModel } from 'src/modules/nltdevskeletons/entity/nltdevskeletons/NltdevskeletonsModel';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { NltdevskeletonsTableRow } from '../tableRow';
import { TableEmptyList } from 'src/UIKit/tableEmptyList';


export const NltdevskeletonsTable: FC = observer(() => {
    const { t } = useUiContext();
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage, scrollRef } = useNltdevskeletonsTable();
    const count = nltdevskeletonsModel.nltdevskeletons?.count ? Math.ceil(nltdevskeletonsModel.nltdevskeletons.count/ 30) : 1;
    const TABLE_HEAD = [t('common.image'), t('common.name'), t('categories.category'), '', t('common.status'), t('common.actions')];

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <ListToolbar search={search} onSearch={onSearch} placeholder={t('content.searchByName')} />
            <TableContainer sx={{ height: "calc(100vh - 350px)", position: "relative" }} ref={scrollRef}>
                <Table>
                    <TableHeader headLabels={TABLE_HEAD} /> 
                    <TableBody>
                        {nltdevskeletonsModel.nltdevskeletons?.rows.map((item) => (<NltdevskeletonsTableRow item={item} key={item.id} />))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack direction='row' justifyContent='center' my={1.5}>
                <Pagination
                    page={page + 1}
                    count={count}
                    color="primary"
                    onChange={onChangePage}
                />
            </Stack>
        </Paper>
    );
})
