import React, { FC } from 'react';
import { Box, Button, ButtonGroup, Card, Stack, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { styles } from './styles';
import { Iconify } from 'src/UIKit/Iconify';
import { INotification } from 'src/modules/notifications/entity/INotification';

interface IProps {
    item: INotification | null;
}

export const NotificationPreview: FC<IProps> = observer(({ item }) => {
    const { t } = useUiContext();

    return (
        <>
            <Typography color='inherit' variant='h6' fontWeight={600}>{t('notifications.preview')}</Typography>
            <Stack sx={styles.container}>
                <Box sx={styles.header}>
                    <Iconify icon="ph:bell-fill" sx={{ width: 16, height: 16, mr: 1 }} />
                    <Typography variant='body2'>Design SIE</Typography>
                </Box>
                <Box sx={styles.data}>
                    <Box sx={styles.dataBox}>
                        <Typography variant='body1' fontWeight={600} sx={styles.dataText}>{item?.title}</Typography>
                        <Typography variant='body2' sx={styles.dataText}>{item?.body}</Typography>
                    </Box>
                    {item?.image?.path ? <Box component="img" sx={styles.image} alt="The notification image" src={item?.image?.path} /> : null}
                </Box>
            </Stack>
        </>
    );
});





