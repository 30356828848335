import { MobXRepository } from "src/repository/MobXRepository";
import { INotification } from "./INotification";

export interface INotificationsModel {
    notifications: { count: number; rows: INotification[] } | null;
    notification: INotification | null;
}

class NotificationsModel implements INotificationsModel {
    private notificationsRepository = new MobXRepository<{ count: number; rows: INotification[] } | null>(null);
    private notificationRepository = new MobXRepository<INotification | null>(null);

    get notifications() {
        return this.notificationsRepository.data;
    }

    set notifications(data: { count: number; rows: INotification[] } | null) {
        this.notificationsRepository.save(data);
    }

    get notification() {
        return this.notificationRepository.data;
    }

    set notification(data: INotification | null) {
        this.notificationRepository.save(data);
    }

    clear = () => {
        this.notifications = null;
        this.notification = null;
    }
}

export const notificationsModel = new NotificationsModel();
