import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";
import { ISubscription } from "./ISubscription";

class SubscriptionsService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    list = async (): Promise<IResponse<{count: number, rows: ISubscription[]}>> => {
        try {
            const response = await this.requester.get(this.links.subscriptions);
            return response;
        } catch (error) {
            console.warn('SubscriptionsService -> list: ', this.links.subscriptions, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    update = async ({id, price}) => {
        try {
            const url = `${this.links.subscriptions}/${id}/prices`;
            const response = await this.requester.patch(url, { price });
            return response;
        } catch (error) {
            console.warn('SubscriptionsService -> update: ', this.links.subscriptions, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };
}

export const subscriptionsService = new SubscriptionsService(requester, links);
