import { useState } from "react";
import { z } from "zod";
import { IResources } from "../entity/resources/IResources";
import { createResourceUseCase } from "../useCases/createResourceUseCase";
import { updateResourceUseCase } from "../useCases/updateResourceUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

const DEFAULT_RESOURCE: any = { name: '', value: '', file: null, type: '', id: 0 };

export const useResourceCreating = (link: string, entityId: number, close: () => void, item: IResources = DEFAULT_RESOURCE, isMedia: boolean, onUpdate?: () => Promise<void>) => {
    const { t } = useUiContext();
    const [resource, setResource] = useState<IResources>(item);
    const [errors, setErrors] = useState({ name: '', value: '', file: '', type: '' });
    const [loading, setLoading] = useState(false);

    const categorySchema = z.object({
        name: z.string().min(1, { message: t('common.error.notEmpty') }),
        value: z.string().nullable(),
        image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: t('common.error.fileSize') }).nullable().optional(),
    });

    const TYPES = isMedia
    ? [
        { name: t('resource.types.video'), id: 'VIDEO' },
        { name: t('resource.types.audio'), id: 'AUDIO' }
    ]
    : [
        { name: t('resource.types.html'), id: 'HTML' },
        { name: t('resource.types.video'), id: 'VIDEO' },
        { name: t('resource.types.audio'), id: 'AUDIO' },
        { name: t('resource.types.image'), id: 'IMAGE' }
        // , { name: 'MARKDOWN', id: 'MARKDOWN' }
    ];
    
    const typeObject = TYPES.find((item) => item.id === resource.type);

    const onChange = (value: string, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: '' }));
        if (field === 'type') {
            setResource((prev) => ({ ...prev, value: '', file: null }));
        }
    };

    const onCreateResource = async () => {
        setLoading(true);
        const result: any = await createResourceUseCase(link, entityId, resource as any);
        if (!result?.isError) {
            toastService.onSuccess(t('resource.message.resourceCreated'));
        }; 
        await onUpdate?.();
        close();
        setLoading(false);
    };

    return { resource, errors, onChange, TYPES, typeObject, onCreateResource, loading };

}