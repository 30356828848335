import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { toastService } from "src/libraries/toast";
import { categoriesService } from "../entity/categories/CategoryService";
import { GetCategoriesDto } from "../entity/categories/dto/list-categories-dto";

export const getCategoriesUseCase = async (body?: GetCategoriesDto) => {
    try {
        const response = await categoriesService.list(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else if (body?.tree) {
            categoriesModel.categoriesTree = response.data;
            categoriesModel.categories = response.data.flat(Infinity);
        } else {
            categoriesModel.categories = response.data;
        }
    } catch (error) {
        console.warn('getCategoriesUseCase: ', error);
    }
};   