import { observer } from "mobx-react";
import React, { FC } from 'react';
import { useUiContext } from "src/UIProvider";
import { useBannerEdit } from "../../presenters/useBannerEdit";
import { Card, Container, Grid } from "@mui/material";
import { DashboardHeader } from "src/UIKit/DashboardHeader";
import { TextInput } from "src/UIKit/TextInput";
import { ResourceFile } from "src/modules/resources/ui/components/resourceFile";
import { IOSSwitch } from "src/UIKit/IOSSwitch";

export const BannerEditView: FC = observer(() => {
    const { t } = useUiContext();
    const { onUpdateBanner, banner, onChange, errors, resource, onChangeFile, onActiveSwitch, loading } = useBannerEdit();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('banner.editBanner')} buttonTitle={t('banner.editBanner')} onPress={onUpdateBanner} isDisabledButton={loading} />
            <Grid item mb={5}>
                <Card sx={{ p: 2, mb: 2 }}>
                    <TextInput label={t('notifications.title') + "*"} name='title' onChange={onChange} value={banner?.title} error={errors} />
                    <TextInput label={t('notifications.text')} name='description' onChange={onChange} value={banner?.description} error={errors} isMultiline rows={3} />
                    <TextInput label={t('notifications.link') + "*"} name='url_link' onChange={onChange} value={banner?.url_link} error={errors} />
                    <ResourceFile item={resource} onChange={onChangeFile} />
                    {banner && <IOSSwitch label={t('common.show')} secondLabel={t('common.hide')} name="available" onChange={onActiveSwitch} checked={banner?.available} />}
                </Card>
            </Grid>
        </Container>
    )
});