import React, { FC, memo } from 'react';
import { Box, Typography, TextField, TextFieldProps, TypographyProps, BoxProps, FormControl, SxProps, Theme } from '@mui/material';

interface IProps {
    value?: string | number | null;
    name: string;
    onChange: (value: string, name: string) => void;
    required?: boolean;
    error?: { [key: string]: string };
    label?: string;
    textFieldProps?: TextFieldProps;
    typographyProps?: TypographyProps;
    containerProps?: BoxProps;
    isDisabled?: boolean;
    sx?: SxProps<Theme>;
    isMultiline?: boolean;
    rows?: number;
    onBlur?: () => void;
};

export const TextInput: FC<IProps> = memo(({ label, name, value = '', onChange, error, required, textFieldProps, typographyProps, containerProps, isDisabled, onBlur, sx, isMultiline, rows }) => {

    const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value, name);
    };

    return (
        <FormControl fullWidth sx={{ mb: 1, ...sx }} >
            <Typography variant='h6' mb={1} {...(typographyProps || {})}>{label}{required ? '*' : ''}</Typography>
            <TextField
                size='small'
                error={!!error?.[name]} value={value || ''}
                onChange={onHandleChange}
                {...(textFieldProps || {})}
                disabled={isDisabled}
                label={label}
                multiline={isMultiline}
                rows={rows}
                onBlur={onBlur}
                autoComplete='off'
            />
            <Typography minHeight={1} variant='caption' color={'red'} {...(typographyProps || {})}>{error?.[name]}</Typography>
        </FormControl>
    )
});
