import { useState } from "react";
import { INltdevskeleton } from "../entity/nltdevskeletons/INltdevskeleton";
import { useNavigate } from "react-router-dom";
import { deleteNltdevskeletonUseCase } from "../useCases/deleteNltdevskeletonUseCase";
import { updateNltdevskeletonUseCase } from "../useCases/updateNltdevskeletonUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";
import { sendContentNotificationUseCase } from "src/modules/notifications/useCases/sendContentNotificationUseCase";

export const useNltdevskeletonsTableItem = (item: INltdevskeleton) => {
    const { t } = useUiContext();
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const informUsers = async() => {
        setLoading(true);
        const result = await sendContentNotificationUseCase(item.id);
        if(!result?.isError){
            toastService.onSuccess(t('notifications.notificationSent'));
        };
        setLoading(false); 
    };

    const onDelete = async () => {
        const result: any = await deleteNltdevskeletonUseCase(item.id);
        if (!result?.isError) {
            toastService.onSuccess(t('content.message.contentRemoved'));
        } 
    };

    const onEdit = () => {
        navigate('/dashboard/nltdevskeletons/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        await updateNltdevskeletonUseCase({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit, informUsers, loading }
}