import { useMemo } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import React, { FC } from 'react';

interface IProps {
    image?: any;
    sx?: SxProps<Theme>
    className?: string;
}

export const Image: FC<IProps> = (({ image, sx = {}, className }) => {

    const src = useMemo(() => {
        if (image?.path?.includes('http')) {
            return image.path;
        } else if (image) {
            return URL.createObjectURL(image);
        } else {
            return '';
        }
    }, [image])

    // return (<img style={{width:'100%',}} src={src} draggable={false} />);
    return (<Box component={'img'} sx={{ ...sx }} src={src} draggable={false} />);
})
