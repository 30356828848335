import { Stack } from '@mui/material';
import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import './styles.css';

export const DashboardProjectHeader: FC = observer(({ }) => {
    return (
        <Stack direction="row" justifyContent="center" alignItems="center" my={4} mx={2.5} component={Link} to='/dashboard/categories'>
            {/* <div className="hover14"> */}
                <div>
                    <figure>
                        {/* <Stack sx={{ borderRadius: '10px 100px / 120px', border: '1px solid black', p: 2, overflow: 'hidden', position: 'relative' }}> */}
                            <img src='/assets/logo.svg' alt='logo' width={100} height={100} />
                        {/* </Stack> */}
                    </figure>
                </div>
            {/* </div> */}

        </Stack >
    );
});
