export const getStyles = (categoryError: string | undefined) => {
    const styles = {
        clearIcon: {
            color: "#757575",
            backgroundColor: "#ffffff",
            position: 'relative',
            top: '-3px',
            right: '7px',
        },
        suffixIcon: {
            color: "#757575",
        },
        wrapper: {
            ".ant-select-selector": {
                borderColor: !!categoryError ? "#FF4B4B !important" : "#DDE0E4 !important",
                backgroundColor: "#ffffff",
                color: "#000000",
                ":hover": {
                    borderColor: "#B0A494"
                },
                ":focus-within": {
                    border: "2px solid #B0A494 !important"
                },
            },
            ".ant-select-selection-placeholder": {
                color: "#9EAAB5 !important",
                fontSize: "16px"
            },
        }
    };
    return styles;
};