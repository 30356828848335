import { toastService } from "src/libraries/toast";
import { subscriptionsService } from "../entity/subscriptions/SubscriptionsService";
import { subscriptionsModel } from "../entity/subscriptions/SubscriptionsModel";

export const getSubscriptionsUseCase = async () => {
    try {
        const response = await subscriptionsService.list();
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            subscriptionsModel.subscriptions = response.data.rows;
        }
    } catch (error) {
        console.warn('getSubscriptionsUseCase: ', error);
    }
};