import { toastService } from "src/libraries/toast";
import { CreateNewsCategoryDto } from "../entity/newsCategories/dto/create-news-category-dto";
import { newsCategoryService } from "../entity/newsCategories/NewsCategoryService";
import { getNewsCategoriesUseCase } from "./getNewsCategoriesUseCase";

export const createNewsCategoryUseCase = async (body: CreateNewsCategoryDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image) {
                formData.append('image', body.image, body.image.name)
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await newsCategoryService.create(formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            await getNewsCategoriesUseCase();
        }
        return response;
    } catch (error) {
        console.warn('createNewsCategoryUseCase: ', error);
    }
}  