import { Tab, Tabs } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import { getStyles } from "./styles";
import React from "react";

interface IProps {
    tab: string;
    tabOptions: any[];
    handleTabsChange: (event: ChangeEvent<{}>, tab: string) => void;
};

export const TabSelector: FC<IProps> = ({ tabOptions, handleTabsChange, tab }) => {
    const styles = useMemo(()=>getStyles(), []);

    return (
        <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            sx={styles.tabs}
            value={tab}
            variant="fullWidth"
        >
            {tabOptions.map((tab) => (
                <Tab
                    key={tab.value}
                    label={tab.label.toUpperCase()}
                    value={tab.value}
                    sx={styles.tab}
                />
            ))}
        </Tabs>
    )
};