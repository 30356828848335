import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteVacancyUseCase } from "../useCases/deleteVacancyUseCase";
import { updateNewsUseCase } from "../useCases/updateVacancyUseCase";
import { IVacancies } from "../entity/vacancies/IVacancies";

export const useVacanciesTableItem = (item: IVacancies) => {
    const [open, setOpen] = useState(null);
    const navigate = useNavigate();

    const onOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const onCloseMenu = () => {
        setOpen(null);
    };

    // const onHandleClick = () => {
    //     onPress(item.id);
    // };

    const onDelete = async () => {
        await deleteVacancyUseCase(item.id);
    };

    const onEdit = () => {
        navigate('/dashboard/vacancies/edit/' + item.id);
    };

    const onActiveSwitch = async () => {
        await updateNewsUseCase({ id: item.id, available: !item.available });
    }

    return { open, onActiveSwitch, onOpenMenu, onCloseMenu, onDelete, onEdit }
}