import { toastService } from "src/libraries/toast";
import { getNewsCategoriesUseCase } from "./getNewsCategoriesUseCase";
import { newsCategoryService } from "../entity/newsCategories/NewsCategoryService";
import { UpdateNewsCategoryDto } from "../entity/newsCategories/dto/update-news-category-dto";

export const updateNewsCategoryUseCase = async (body: UpdateNewsCategoryDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image && !body.image?.path?.includes('http')) {
                formData.append('image', body.image, body.image.name)
            } else if (key === 'image' && !body.image?.path?.includes('http')) {
                formData.append('image', 'null')
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await newsCategoryService.update(body.id, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Success');
            await getNewsCategoriesUseCase();
        }
        return response;
    } catch (error) {
        console.warn('updateNewsCategoryUseCase: ', error);
    }
}  