import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from 'zod';
import { getFeaturesByIdUseCase } from "../useCases/getFeaturesByIdUseCase";
import { updateFeaturesUseCase } from "../useCases/updateFeaturesUseCase";
import { featuresModel } from "../entity/features/FeaturesModel";

const featuresSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    description: z.string().nullable(),
    development_side: z.string().nullable(),
    slug: z.string().min(1, { message: "Not empty" }),
    available: z.boolean(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }).nullable(),
});

const DEVELOPMENT_SIDES = [{ name: 'Front-end', id: 'Front-end' }, { name: 'Back-end', id: 'Back-end' }, { name: 'Full-stack', id: 'Full-stack' }, { name: 'Mobile', id: 'Mobile' }];

export const useFeaturesEditing = () => {
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '' });
    const features = featuresModel.feature;
    const navigate = useNavigate();

    useEffect(() => {
        getFeaturesByIdUseCase(Number(id));
        return () => { featuresModel.features = null; }
    }, []);

    const onChange = (value: string, field: string) => {
        if (featuresModel.feature) {
            featuresModel.feature = { ...featuresModel.feature, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onUpdateFeatures = async () => {
        setLoading(true);
        const result: any = featuresSchema.safeParse({ ...featuresModel.feature });
        if (result.success && featuresModel.feature) {
            await updateFeaturesUseCase(featuresModel.feature as any);
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    const onRefresh = async () => {
        await getFeaturesByIdUseCase(Number(id));
    }

    return { DEVELOPMENT_SIDES, features, errors, onChange, onUpdateFeatures, onRefresh };

}