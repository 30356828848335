import React, { FC } from 'react';
import { Box, Card } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useResources } from '../../presenters/useResources';
import { IResources } from '../../entity/resources/IResources';
import { ResourcesCreatingView } from '../ResourcesCreating';
import { PreviewResource } from '../components/previewResource';

interface IProps {
    slug?: string;
    link: string;
    entityId?: number;
    resources: IResources[];
    onUpdate?: () => Promise<void>;
}

export const ResourcesView: FC<IProps> = observer(({ resources, link, entityId = 0, onUpdate, slug }) => {
    const { t } = useUiContext(); 
    const { visible, onOpen, onClose } = useResources(); 

    const isMedia = !slug?.includes('blog');

    return (
        <Box>
            {resources?.length ? <Card sx={{ p: 2, mb: 2, alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                {resources?.map((item) => (<PreviewResource length={resources?.length} link={link} entityId={entityId} onUpdate={onUpdate} key={item.id} item={item} isMedia={isMedia}/>))}
            </Card> : null}
            {
                visible
                    ? <ResourcesCreatingView close={onClose} link={link} entityId={entityId} onUpdate={onUpdate} isMedia={isMedia}/>
                    : <DashboardHeader title={t('resource.resources')} buttonTitle={t('resource.createResource')} onPress={onOpen} />
            }
        </Box>
    );
})
