import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { Box, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styles } from './styles';
import { useUiContext } from 'src/UIProvider';
import React from 'react';

interface IProp {
    isDisabled?: boolean;
    time: string;
    checked: boolean;
    onChangeSwitch: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeTime: (newDateTime: any) => void;
    onAcceptTime: () => void;
};

export const ResponsiveDateTimePickers: FC<IProp> = ({ time, checked, onChangeSwitch, onChangeTime, onAcceptTime, isDisabled }) => {
    const { t, locale } = useUiContext();

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, [window.history]);

    const localeText = {
        cancelButtonLabel: t('notifications.cancel'),
        clearButtonLabel: t('notifications.clear'),
        okButtonLabel: 'Ок',
        todayButtonLabel: t('notifications.today'),
        datePickerToolbarTitle: t('notifications.chooseDate'),
        dateTimePickerToolbarTitle: t('notifications.chooseDateAndTime'),
    };

    return (
        <Box>
            <Stack sx={styles.card}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <Stack direction='row' spacing={3} sx={styles.timeHeader}>
                        <Typography color='inherit' variant='h5' fontWeight={600}>{t('notifications.putOffNotification')}</Typography>
                        <FormControlLabel control={<Switch checked={checked} onChange={onChangeSwitch} disabled={isDisabled} />} label={t('notifications.timeSending')} />
                    </Stack>
                    {checked &&
                        <StaticDateTimePicker disablePast value={dayjs(time)} onChange={onChangeTime} onAccept={onAcceptTime} disabled={isDisabled} localeText={localeText} />
                    }
                </LocalizationProvider>
            </Stack>
        </Box>
    );
};
