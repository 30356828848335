import React from 'react';
import SvgColor from '../../components/svg-color';

// @ts-ignore
const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const NavConfig = (t: (key: string) => string) => {

  return [
    {
      title: t('categories.categories'),
      path: '/dashboard/categories',
      icon: icon('list-circle'),
    },
    {
      title: t('content.content'),
      path: '/dashboard/nltdevskeletons',
      icon: icon('newspaper'),
    }, 
    {
      title: t('notifications.notifications'),
      path: '/dashboard/notifications',
      icon: icon('ic_round-circle-notifications'),
    }, 
    {
      title: t('banner.banner'),
      path: '/dashboard/banners',
      icon: icon('settings-fill'),
    }, 
    {
      title: t('subscriptions.subscriptions'),
      path: '/dashboard/product',
      icon: icon('ic_notification_chat'), 
    },
    // {
    //   title: 'News categories',
    //   path: '/dashboard/news-categories',
    //   icon: icon('newspaper'),
    // },
    // {
    //   title:  t('content.content'),
    //   path: '/dashboard/news',
    //   icon: icon('newspaper'),
    // },
    // {
    //   title: 'Features',
    //   path: '/dashboard/features',
    //   icon: icon('newspaper'),
    // },
    // {
    //   title: 'Vacancies',
    //   path: '/dashboard/vacancies',
    //   icon: icon('newspaper'),
    // },
  ];
};

export default NavConfig;
