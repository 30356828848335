import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { useNotificationEdit } from '../../presenters/useNotificationEdit';
import { ResponsiveDateTimePickers } from '../components/responsiveDateTimePicker';
import { TextInput } from 'src/UIKit/TextInput';
import { observer } from 'mobx-react';
import { ResourceFile } from 'src/modules/resources/ui/components/resourceFile';
import { Select } from 'src/UIKit/Select';

export const NotificationEditView: FC = observer(() => {
    const { t } = useUiContext();
    const { onUpdateNotification, time, checked, onChangeSwitch, onChangeTime, onAcceptTime, onChange, notification, resource, onChangeFile, IMAGE_TYPES,
        typeObject, errors, isDeleted
     } = useNotificationEdit();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('notifications.notification') + `, ${notification?.title}`} buttonTitle={t('notifications.editNotification')} onPress={onUpdateNotification} />
            <Grid item mb={5}>
                <Card sx={{ p: 2, mb: 2 }}>
                    <TextInput label={t('notifications.title') + "*"} name='title' onChange={onChange} value={notification?.title} error={errors} isDisabled={isDeleted}/>
                    <TextInput label={t('notifications.text') + "*"} name='body' onChange={onChange} value={notification?.body} error={errors} isDisabled={isDeleted} isMultiline rows={3}/>
                    <Select label={t('notifications.image')} name='type' onChange={onChange} value={typeObject?.name || ''} options={IMAGE_TYPES} isDisabled={isDeleted}/>
                    {notification?.type === 'LINK_IMAGE' ? 
                    <TextInput label={t('notifications.imageUrl')} name='image' onChange={onChange} value={notification?.image} isDisabled={isDeleted}/>
                    : <ResourceFile item={resource} onChange={onChangeFile} isDisabled={isDeleted}/>}
                    <TextInput label={t('notifications.link')} name='navigation_url' onChange={onChange} value={notification?.navigation_url} isDisabled={isDeleted}/>
                </Card>
                <ResponsiveDateTimePickers time={time} checked={checked} onChangeSwitch={onChangeSwitch} onChangeTime={onChangeTime} onAcceptTime={onAcceptTime} isDisabled={isDeleted}/>
            </Grid>
        </Container>
    );
});
