import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import { useUiContext } from 'src/UIProvider';

interface IProps {
    onClose?: () => void;
    onSave?: () => void;
    isDisabledButton?: boolean;
}

export const ResourceControls: FC<IProps> = ({ onClose, onSave, isDisabledButton }) => {
    const { t } = useUiContext();
    
    return (
        <Stack direction="row" alignItems="center" justifyContent='flex-end' mb={2} mt={2}>
            {isDisabledButton && <Typography mr={3} pt={1.5}>{t('common.waitingForLoading')}</Typography>}
            <Button variant="contained" onClick={onClose} sx={{ mr: 2 }} disabled={isDisabledButton}>
                {t('common.close')}
            </Button>
            <Button variant="contained" onClick={onSave} disabled={isDisabledButton}>
                {t('common.save')}
            </Button>
        </Stack>
    )
};
