import React, { FC } from 'react';
import { Box, Divider } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { IResources } from 'src/modules/resources/entity/resources/IResources';
import { ResourcePreviewHeader } from '../resourcePreviewHeader';
import { PreviewAudio } from '../previewAudio';
import { PreviewVideo } from '../previewVideo';
import { useResourcesItem } from 'src/modules/resources/presenters/useResourcesItem';
import { PreviewImage } from '../previewImage';
import { ResourcesEditView } from '../../ResourcesEdit';
import { PreviewMarkdown } from '../previewMarkdown';

interface IProps {
    isMedia: boolean;
    item: IResources;
    length?: number;
    link?: string;
    entityId?: number;
    onUpdate?: () => Promise<void>;
}

export const PreviewResource: FC<IProps> = observer(({ item, link, entityId, onUpdate, length, isMedia }) => {
    const { t } = useUiContext();
    const { edit, onEditOpen, onEditClose, onDelete } = useResourcesItem(item, link, entityId, onUpdate);

    return (
        <Box sx={{ maxWidth: '1000px', width: '100%' }} >
            <ResourcePreviewHeader title={item.type} onDelete={onDelete} onEdit={onEditOpen} />
            {item?.type === 'HTML' && !edit ? <div dangerouslySetInnerHTML={{ __html: item.value }} style={{ marginTop: '15px' }} /> : null}
            {item?.type === 'IMAGE' && !edit ? <PreviewImage item={item} /> : null}
            {item?.type === 'AUDIO' && !edit ? <PreviewAudio item={item} /> : null}
            {item?.type === 'VIDEO' && !edit ? <PreviewVideo item={item} /> : null}
            {item?.type === 'MARKDOWN' && !edit ? <PreviewMarkdown item={item} /> : null}
            {edit && link && entityId ? <ResourcesEditView item={item} length={length || 0} close={onEditClose} link={link} entityId={entityId} onUpdate={onUpdate} isMedia={isMedia}/> : null}
            <Divider variant="fullWidth" sx={{ my: 2, borderBottomWidth: 4 }} />
        </Box>
    );
})
