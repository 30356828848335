import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";
import { INewsCategory } from "./INewsCategory";

class NewsCategoryService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    create = async (body: FormData): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.postFormData(this.links.newsCategory, body);
            return response;
        } catch (error) {
            console.warn('NewsCategoryService -> create: ', this.links.newsCategory, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    list = async (): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.get(this.links.newsCategory);
            return response;
        } catch (error) {
            console.warn('NewsCategoryService -> list: ', this.links.newsCategory, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    update = async (id: number, body: FormData) => {
        try {
            const url = this.links.newsCategory + '/' + id;
            const response = await this.requester.patchFormData(url, body);
            return response;
        } catch (error) {
            console.warn('NewsCategoryService -> update: ', this.links.newsCategory, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    get = async (id: number | string): Promise<IResponse<INewsCategory>> => {
        try {
            const response = await this.requester.get(this.links.newsCategory + '/' + id);
            return response;
        } catch (error) {
            console.warn('NewsCategoryService -> get: ', this.links.newsCategory, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    delete = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.delete(this.links.newsCategory + '/' + id);
            return response;
        } catch (error) {
            console.warn('NewsCategoryService -> delete: ', this.links.newsCategory, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

}

export const newsCategoryService = new NewsCategoryService(requester, links);