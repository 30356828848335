import { useEffect, useState } from "react";
import { categoriesModel } from "src/modules/categories/entity/categories/CategoryModel";
import { ICategory } from "src/modules/categories/entity/categories/ICategory";
import { useNavigate, useParams } from "react-router-dom";
import { z } from 'zod';
import { getCategoriesUseCase } from "../useCases/getCategoriesUseCase";
import { updateCategoryUseCase } from "../useCases/updateCategoryUseCase";
import { getCategoryUseCase } from "../useCases/getCategoryUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

export const useCategoryEditing = () => { 
    const { t } = useUiContext();
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', parent_id: '', image: '' });
    const category = categoriesModel.category;
    const categories = categoriesModel.categories;
    const navigate = useNavigate();

    const categorySchema = z.object({
        name: z.string().min(1, { message: t('common.error.notEmpty') }),
        // description: z.string().nullable(),
        slug: z.string().min(1, { message: t('common.error.notEmpty') }),
        parent_id: z.union([z.number(), z.string()]).nullable().optional(),
        image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: t('common.error.fileSize') }).nullable().optional(),
    });

    useEffect(() => {
        if (!categories.length) {
            getCategoriesUseCase({ tree: true });
        }
        getCategoryUseCase(Number(id));
        return () => { categoriesModel.category = null; }
    }, []);

    const findCategory = (categories: any, category_id: any) => {
        for (let category of categories) {
            if (category.id === category_id) {
                return category;
            }
            if (category.children && category.children.length > 0) {
                const found = findCategory(category.children, category_id);
                if (found) {
                    return found;
                }
            }
        }
        return null;
    };

    const parent = findCategory(categories, category?.parent_id);
    const options = categories?.map((item: ICategory) => ({ id: item.id, name: item.name, value: item.id }));

    const onChange = (value: string, field: string) => {
        if (categoriesModel.category) {
            categoriesModel.category = { ...categoriesModel.category, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    };

    const onSelectCategory = (selectedKeysValue: string, node: any) => {
        if (categoriesModel.category) {
            categoriesModel.category = { ...categoriesModel.category, parent_id: node.value };
        }
    };

    const onChangeCategory = () => {
        if (categoriesModel.category) {
            categoriesModel.category = { ...categoriesModel.category, parent_id: null };
        }
    };

    const onUpdateCategory = async () => { 
        setLoading(true);
        const result: any = categorySchema.safeParse({ ...categoriesModel.category });
        if (result.success && categoriesModel.category) {
            const result = await updateCategoryUseCase({ 
                id: categoriesModel.category.id, name: categoriesModel.category.name,  image: categoriesModel.category.image, parent_id: categoriesModel.category.parent_id });
            if (!result?.isError) {
                toastService.onSuccess(t('categories.message.categoryUpdated'));
                navigate(`/dashboard/categories`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    };

    return { category, options, parent, errors, onChange, onUpdateCategory, categories, onSelectCategory, onChangeCategory, loading };
};