import React, { FC } from 'react';
import { Card, Container, Pagination, Stack, Table, TableBody, TableContainer } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { useNotificationsList } from '../../presenters/useNotificationsList';
import { observer } from 'mobx-react-lite';
import { notificationsModel } from '../../entity/NotificationModel';
import { NotificationCard } from '../components/notificationCard';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableEmptyList } from 'src/UIKit/tableEmptyList';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { TabSelector } from 'src/UIKit/tabSelector/tabSelector';

export const NotificationStatus = {
    READY: "READY",
    PENDING: "PENDING",
    COMPLETED: "COMPLETED",
    ERROR: "ERROR",
    DELETED: "DELETED"
};

export const NotificationsListView: FC = observer(() => {
    const { t } = useUiContext();
    const { onDeleteNotification, onGoToEditNotification, onGoToCreateNotification, onGoToNotificationDetails, onSearch, onChangePage, scrollRef,
    page, search, tab, handleTabsChange } = useNotificationsList();
    const count = notificationsModel.notifications?.count ? Math.ceil(notificationsModel.notifications.count / 30) : 1;
    const TABLE_HEAD = [t('notifications.table.image'), t('notifications.table.name'), t('notifications.table.content'), t('notifications.table.sent'),t('notifications.table.status'), ''/* t('notifications.table.actions') */];
    const tabOptions = [
        { label: t('notifications.ready'), value: NotificationStatus.READY }, 
        // { label: t('notifications.pending'), value: NotificationStatus.PENDING },
        { label: t('notifications.completed'), value: NotificationStatus.COMPLETED }, 
        // { label: t('notifications.error'), value: NotificationStatus.ERROR }, 
        { label: t('notifications.deleted'), value: NotificationStatus.DELETED }, 
    ];

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('notifications.notifications')} buttonTitle={t('notifications.createNotification')} onPress={onGoToCreateNotification} />
            <Card>
                <ListToolbar search={search} onSearch={onSearch} placeholder={t('notifications.searchNotifications')} />
                <TabSelector tab={tab} tabOptions={tabOptions} handleTabsChange={handleTabsChange} />
                <Scrollbar >
                    <TableContainer sx={{ minWidth: 800 }} ref={scrollRef}>
                            <Table>
                                <TableHeader headLabels={TABLE_HEAD} />
                                <TableBody >
                                    {notificationsModel.notifications?.rows.map((item) => (
                                        <NotificationCard
                                            item={item}
                                            key={item.id}
                                            onPress={onGoToNotificationDetails}
                                            onDeleteNotification={onDeleteNotification}
                                            onGoToEditNotification={onGoToEditNotification}
                                        />
                                    ))}
                                </TableBody>
                                {/* <TableEmptyList /> */}
                            </Table>
                    </TableContainer>
                </Scrollbar>
                {!!notificationsModel.notifications?.rows.length &&
                    <Stack direction='row' justifyContent='center' my={2}>
                    <Pagination
                        page={page + 1}
                        count={count}
                        color="primary"
                        onChange={onChangePage}
                    />
                </Stack>}
            </Card>
        </Container>
    );
});
