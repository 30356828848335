import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authorizationUseCase } from '../useCases/authorizationUseCase';
import { z } from 'zod';
import { useUiContext } from 'src/UIProvider';

type ErrorsState = {
    email: string;
    password: string;
};

export const useAuthorization = () => {
    const { t } = useUiContext();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<ErrorsState>({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showNewComponent, setShowNewComponent] = useState<boolean>(false);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    const navigate = useNavigate();

    const errorsSchema = z.object({
        email: z.string().email({ message: t('auth.error.email') }),
        password: z.string(),
    });

    useEffect(() => {
        return () => {
            setEmail('');
            setPassword('');
            setIsLoading(false);
            setShowPassword(false);
        }
    }, []);

    useEffect(() => {
        const handleLoad = () => setImageLoaded(true);
        document.querySelector('img')?.addEventListener('load', handleLoad);
        return () => {
            document.querySelector('img')?.removeEventListener('load', handleLoad);
        };
    }, []);

    useEffect(() => {
        if (imageLoaded) {
            const timer = setTimeout(() => {
                setShowNewComponent(true);
            }, 1700);

            return () => clearTimeout(timer);
        }
    }, [imageLoaded]);

    const onLogin = async () => {
        setIsLoading(true);
        const result: any = errorsSchema.safeParse({ email, password });        
        if (result.success) {
            const response = await authorizationUseCase({ email, password });
            if (response?.isError) {
                setErrors((prev: any) => ({ ...prev, password: response.message }));
            } else {
                navigate('/dashboard/categories'), { replace: true };
            }
        } else {
            result.error.issues.map((item: any) => { setErrors((prev) => ({ ...prev, [item.path[0]]: item.message })); });
        }
        setIsLoading(false);
    };

    const onShowPassword = () => {
        setShowPassword(prev => !prev);
    };

    const onChangEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmail(e.target.value);
        setErrors((prev) => ({ ...prev, email: '' }));
    };

    const onChangPassword = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setPassword(e.target.value);
        setErrors((prev) => ({ ...prev, password: '' }));
    };

    return { isLoading, errors, showPassword, email, password, onLogin, onShowPassword, onChangEmail, onChangPassword, showNewComponent };
};
