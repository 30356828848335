export const de = {
    common: {
        create: 'Erstellen',
        edit: 'Bearbeiten',
        delete: 'Löschen',
        save: 'Speichern',
        close: 'Schließen',
        send: 'Schicken',
        active: 'aktiv',
        noActive: 'nicht aktiv',
        name: 'Name',
        description: 'Beschreibung',
        slug: 'Slug',
        none: 'Keiner',
        image: 'Bild',
        parent: 'Elternteil',
        free: "Kostenlos",
        noFree: "Nicht kostenlos",
        waitingForLoading: "bitte warten, der Inhalt wird geladen ...",
        status: 'Status',
        actions: 'Aktionen',
        watch: "Aussehen",
        show: "Angezeigt",
        hide: "Versteckt",
        message: {
            pressToAddFile: 'Klicken Sie, um eine Datei hinzuzufügen, oder ziehen Sie sie per Drag & Drop hierher',
            imageFormats: '(formats svg, png, jpg or jpeg, maximum size 1 MB)',
        },
        error: {
            notEmpty: 'Sollte nicht leer sein',
            fileSize: 'Die Dateigröße sollte kleiner oder gleich 1 MB sein',
            invalidUrl: 'Die URL ist nicht korrekt'
        }
    },
    locale: {
        english: 'Englisch',
        german: 'Deutsch',
    },
    auth: {
        email: 'E-Mail Adresse',
        password: 'Passwort',
        signIn: 'Anmelden',
        signOut: 'Ausloggen',
        error: {
            email: 'E-Mail-Adresse ist ungültig',
            password: 'Das Feld muss mindestens 8 Zeichen lang sein',
        }
    },
    user: {
        fullName: 'Vollständiger Name'
    },
    categories: {
        categories: 'Kategorien',
        category: 'Kategorie',
        editCategory: 'Kategorie bearbeiten',
        categoryIcon: 'Kategorie-Icon',
        parentCategory: 'Übergeordnete Kategorie',
        message: {
            categoryUpdated: 'Kategorie aktualisiert',
            categorySwapped: 'Kategorie vertauscht',
            categoryAccessSwitched: 'Kategorie Zugang umgeschaltet',
        }
    },
    content: {
        content: 'Inhalt',
        createContent: 'Inhalt erstellen',
        editContent: 'Inhalt bearbeiten',
        searchByName: 'Suche mit Name',
        contentIcon: 'Inhalt-Icon',
        parentCategory: 'Übergeordnete Kategorie',
        message: {
            contentCreated: 'Inhalt erstellt',
            contentUpdated: 'Inhalt aktualisiert',
            contentRemoved: 'Inhalt entfernt',
        }
    },
    resource: {
        resources: 'Resourcen',
        createResource: 'Resourcen erstellen',
        resourceType: 'Resourcetyp',
        sortNumber: 'Sort. Nummer',
        message: {
            resourceCreated: 'Resourcen erstellt',
            resourceUpdated: 'Resourcen aktualisiert',
            resourceRemoved: 'Resourcen entfernt',
        },
        types: {
            html: 'Html',
            video: 'Video',
            audio: 'Audio',
            image: 'Image',
        }
    },
    notifications: {
        inform: "Informieren",
        notifications: "Benachrichtigungen",
        notification: "Benachrichtigung",
        createNotification: "Benachrichtigung erstellen",
        editNotification: "Benachrichtigung bearbeiten",
        newNotification: 'Neue benachrichtigung',
        title: 'Titel',
        enterTitle: 'Titel eingeben',
        text: 'Text',
        imageUrl: 'Bild URL',
        link: 'Link',
        putOffNotification: 'Benachrichtigungen per Schlummerfunktion',
        timeSending: 'Zeitpunkt der Benachrichtigung',
        chooseTime: "Wähle Zeit",
        searchNotifications: 'Benachrichtigungen durchsuchen',
        ready: 'Warte darauf, verschickt zu werden',
        pending: 'Ausstehend',
        error: 'Fehler',
        completed: 'Abgeschlossen',
        deleted: 'Gelöscht',
        image: 'Bild',
        cancel: 'Abbrechen',
        today: 'Heute',
        chhoseDate: 'Datum wählen',
        chooseDateAndTime: 'Datum und Uhrzeit wählen',
        clear: 'Klar',
        timeSet: 'Die Zeit ist eingestellt',
        details: 'Einzelheiten zur Benachrichtigung',
        header: 'Überschrift',
        content: 'Inhalt',
        status: 'Status',
        time: 'Zeit',
        preview: 'Vorschau der Benachrichtigung',
        notificationSent: 'Benachrichtigung gesendet',
        table: {
            image: 'Bild',
            name: 'Name',
            content: 'Inhalt',
            staus: 'Status',
            actions: 'Aktionen',
            sent: 'Gesendet',
        }
    },
    banner: {
        banner: "Banner", 
        editBanner: "Banner bearbeiten", 
        message: {
            bannerUpdated: "Banner aktualisiert"
        }
    },
    subscriptions: {
        subscriptions: "Abonnements",
        priceChanged: "Preis geändert",
        tooMuchSymbols: "Der Preis muss weniger als 7 Ziffern lang sein",
        name: "Name",
        type: "Typ",
        duration: "Dauer",
        created: "Erstellt",
        currency: "Währung",
        price: "Preis",
        day: "Tag",
        week: "Woche",
        month: "Monat",
        year: "Jahr"
    }
};
