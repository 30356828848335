import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { INewsClient } from 'src/modules-client/news/entity/newsClient/INewsClient';
import { useNavigate } from 'react-router-dom';

interface IPostCard {
    item: INewsClient;
}

export const NewsTop: FC<IPostCard> = ({ item }) => {
    const createdAt = new Date(item.createdAt).toLocaleDateString();

    return (
        <div className='flex flex-col w-full' >
            <div className='flex flex-col mx-20 mt-8'>
                <h2 className='text-4xl mb-2' >{item.name}</h2>
                <p >{createdAt}</p>
            </div>
            {item.image ? <CardMedia component="img" sx={{ maxHeight: 300, objectFit: 'contain' }} image={item.image?.path} alt={item.name} /> : null}
        </div>
    );
}