import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { DashboardSideBarContent } from './components/dashboardSideBarContent';
import { useDashboard } from '../presenters/useDashboard';
import { useInitApplication } from 'src/hooks/useInitApplication';
import useResponsive from 'src/hooks/useResponsive';

const NAV_WIDTH = 280;

export const DashboardSideBarView: FC<any> = observer(({ openNav, onCloseNav }) => {
    const { pathname } = useLocation();
    const isDesktop = useResponsive('up', 'lg');
    useDashboard();
    useInitApplication();

    useEffect(() => {
        if (openNav) {
            onCloseNav();
        }
    }, [pathname]);

    return (
        <Box component="nav" sx={{ flexShrink: { lg: 0 }, width: { lg: NAV_WIDTH }}} >
            {isDesktop ? (
                <Drawer
                    open
                    variant="permanent"
                    PaperProps={{ sx: { width: NAV_WIDTH, bgcolor: '#F1EDE2', borderRightStyle: 'dashed', }, }}
                >
                    <DashboardSideBarContent />
                </Drawer>
            ) : (
                <Drawer
                    open={openNav}
                    onClose={onCloseNav}
                    ModalProps={{ keepMounted: true, }}
                    PaperProps={{ sx: { width: NAV_WIDTH }, }}
                >
                    <DashboardSideBarContent />
                </Drawer>
            )}
        </Box>
    );
});
