import { useMemo, useState } from "react";
import { z } from "zod";
import { IResources } from "../entity/resources/IResources";
import { updateResourceUseCase } from "../useCases/updateResourceUseCase";
import { useUiContext } from "src/UIProvider";
import { toastService } from "src/libraries/toast";


export const useResourceEdit = (link: string, entityId: number, close: () => void, item: IResources, length: number, isMedia: boolean, onUpdate?: () => Promise<void>) => {
    const { t } = useUiContext();
    const [resource, setResource] = useState<IResources>(item);
    const [errors, setErrors] = useState({ name: '', value: '', file: '', type: '' });
    const [loading, setLoading] = useState(false);

    const TYPES = isMedia
    ? [
        { name: t('resource.types.video'), id: 'VIDEO' },
        { name: t('resource.types.audio'), id: 'AUDIO' }
    ]
    : [
        { name: t('resource.types.html'), id: 'HTML' },
        { name: t('resource.types.video'), id: 'VIDEO' },
        { name: t('resource.types.audio'), id: 'AUDIO' },
        { name: t('resource.types.image'), id: 'IMAGE' }
        // , { name: 'MARKDOWN', id: 'MARKDOWN' }
    ];

    const validateSchema = z.object({
        name: z.string().min(1, { message: t('common.error.notEmpty') }),
        value: z.string().nullable(),
        image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: t('common.error.fileSize') }).nullable().optional(),
    });

    const typeObject = TYPES.find((item) => item.id === resource.type);

    const sort_numbers = useMemo(() => {
        return new Array(length).fill(null).map((_, index) => ({ name: String(index + 1), id: index + 1 }));
    }, [length])

    const sort_number = sort_numbers.find((item) => item.id === resource.sort_number);

    const onChange = (value: string, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
        setErrors((prev) => ({ ...prev, [field]: '' }));
        if (field === 'type') {
            setResource((prev) => ({ ...prev, value: '', file: null }));
        }
    };

    const onUpdateResource = async () => {
        setLoading(true);
        const result: any = await updateResourceUseCase(link, entityId, resource as any);
        if (!result?.isError) {
            toastService.onSuccess(t('resource.message.resourceUpdated'));
        }; 
        await onUpdate?.();
        close();
        setLoading(false);
    };

    return { resource, errors, sort_numbers, sort_number, onChange, TYPES, typeObject, onUpdateResource, loading };

}