import { useEffect, useState } from "react";
import { useTable } from "src/hooks/useTable";
import { useDebounce } from "src/hooks/useDebounce";
import { subscriptionsService } from "../entity/subscriptions/SubscriptionsService";
import { getSubscriptionsUseCase } from "../useCases/getSubscriptionsUseCase";

export const useSubscriptionsTable = () => {
    const [loading, setLoading] = useState(false);
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useTable();

    useEffect(() => {
        onGetList();
    }, [rowsPerPage, page])

    useEffect(() => {
        debouncedWrapper();
    }, [search])

    const onGetList = async () => {
        if (!loading) {
            setLoading(true);
            await getSubscriptionsUseCase();
            setLoading(false);
        }
    };

    const { debouncedWrapper } = useDebounce(onGetList, 500)

    return { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage };
};