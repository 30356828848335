import React, { FC } from 'react';
import { Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { SubscriptionTable } from '../components/table';

export const SubscriptionsView: FC = observer(() => {
    const { t } = useUiContext();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t("subscriptions.subscriptions")} view='subscription'/>
            <SubscriptionTable />
        </Container>
    );
});
