import React, { FC } from "react";
import { Droppable } from "react-beautiful-dnd";
import { ICategory } from "src/modules/categories/entity/categories/ICategory";
import { StyledDraggedContainer } from "./styles";
import { CategoryListItem } from "../categoryListItem";

interface IProps {
    categories: ICategory[];
    level: number;
    parent?: ICategory;
};

export const CategoryDroppableContainer: FC<IProps> = ({ categories, level, parent }) => {

    return (
        <Droppable droppableId={`droppable_category_${level}`} type={`PARENT_CATEGORY_${parent?.id || ''}`}>
            {(provided, snapshot) => (
                <StyledDraggedContainer ref={provided.innerRef} isDraggingOver={snapshot.isDraggingOver} >
                    {categories.map((category, index) => (<CategoryListItem category={category} index={index} level={level} key={`CategoryDroppableContainer${category.id}${0}`} />))}
                    {provided.placeholder}
                </StyledDraggedContainer>
            )}
        </Droppable>
    );
};
