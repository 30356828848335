export const styles = {
    container: {
        p: 2,
        width: '100%',
        my: 2,
        flexDirection: {
            xs: 'column',
            sm: 'row'
        },
        display: 'flex',
    },
    image: {
        mx: {
            xs: 2,
            sm: 0
        },
        display: 'flex',
        borderRadius: 2,
        height: 150,
        width: 150,
        maxHeight: 150,
        maxWidth: 150,
        objectFit: 'cover',
    }
}