import { useEffect, useState } from "react";
import { getNewsUseCase } from "../useCases/getNewsUseCase";
import { useTable } from "src/hooks/useTable";
import { useDebounce } from "src/hooks/useDebounce";

export const useNewsTable = () => {
    const [loading, setLoading] = useState(false);
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useTable();

    useEffect(() => {
        onGetList();
    }, [rowsPerPage, page])

    useEffect(() => {
        debouncedWrapper();
    }, [search])

    const onGetList = async () => {
        if (!loading) {
            setLoading(true);
            await getNewsUseCase({ limit: rowsPerPage, offset: page * rowsPerPage, search });
            setLoading(false);
        }
    }

    const { debouncedWrapper } = useDebounce(onGetList, 500)

    return { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage };

}