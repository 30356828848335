import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { notificationsModel } from "../entity/NotificationModel";
import { getNotificationsUseCase } from "../useCases/getNotificationsUseCase";
import { useTable } from "src/hooks/useTable";
import { useDebounce } from "src/hooks/useDebounce";
import { deleteNotificationUseCase } from "../useCases/deleteNotificationUseCase";

export const useNotificationsList = () => {
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState('READY');
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage, scrollRef } = useTable();
    const navigate = useNavigate();

    useEffect(() => {
        onGetNotifications();
    }, [page, rowsPerPage, tab]);

    useEffect(() => {
        return () => { notificationsModel.notifications = { rows: [], count: 0 }; }
    }, []);

    useEffect(() => {
        debouncedWrapper();
    }, [search]);

    const onGetNotifications = async () => {
        if (!loading) {
            setLoading(true);
            await getNotificationsUseCase(getRequestBody());
            setLoading(false);
        }
    };

    const { debouncedWrapper } = useDebounce(onGetNotifications, 500);

    const getRequestBody = () => {
        const body: any = { limit: rowsPerPage, offset: page * rowsPerPage, search, status: tab };
        return body;
    };

    const onDeleteNotification = async (id: number) => {
        await deleteNotificationUseCase(Number(id));
        await onGetNotifications();
    };

    const onGoToCreateNotification = useCallback(() => {
        navigate('/dashboard/notifications/create');
    }, []);

    const onGoToNotificationDetails = useCallback((id: number) => {
        navigate(`/dashboard/notifications/details/${id}`);
    }, []);

    const onGoToEditNotification = useCallback((id: number) => {
        navigate(`/dashboard/notifications/edit/${id}`);
    }, []);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        setTab(tab);
    };

    return {
        onDeleteNotification, onGoToEditNotification, onGoToCreateNotification, onGoToNotificationDetails, onSearch, onChangePage, onChangeRowsPerPage,
        scrollRef, rowsPerPage, page, search, tab, handleTabsChange
    };
}
