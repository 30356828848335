import { useEffect } from "react";
import { newsClientModel } from "../entity/newsClient/NewsClientModel";
import { useParams } from "react-router-dom";
import { newsClientService } from "../entity/newsClient/NewsClientService";

export const useNews = () => {
    const slug = useParams().slug;
    const news = newsClientModel.current;

    useEffect(() => {
        slug && newsClientService.get(slug);
        return () => { newsClientModel.current = null };
    }, []);

    return { news };
}