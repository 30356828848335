import { toastService } from "src/libraries/toast";
import { nltdevskeletonsModel } from "../entity/nltdevskeletons/NltdevskeletonsModel";
import { nltdevskeletonsService } from "../entity/nltdevskeletons/NltdevskeletonsService";
import { GetNltdevskeletonsDto } from "../entity/nltdevskeletons/dto/list-categories-dto";

export const getNltdevskeletonsUseCase = async (body?: GetNltdevskeletonsDto) => {
    try {
        const response = await nltdevskeletonsService.list(body);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            nltdevskeletonsModel.nltdevskeletons = response.data;
        }
    } catch (error) {
        console.warn('getNltdevskeletonsUseCase: ', error);
    }
}   