import { MobXRepository } from "src/repository/MobXRepository";
import { IBanner } from "./IBanner";

export interface IBannerModel {
    banner: IBanner | null;
};

class BannerModel implements IBannerModel {
    private bannerRepository = new MobXRepository<IBanner | null>(null);

    get banner() {
        return this.bannerRepository.data;
    };

    set banner(data: IBanner | null) {
        this.bannerRepository.save(data);
    };

    clear = () => {
        this.banner = null;
    };
};

export const bannerModel = new BannerModel();
