import { styled } from '@mui/material/styles';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { alpha } from '@mui/material/styles';
import Popover, { PopoverProps } from '@mui/material/Popover';

interface AccountIconButtonProps extends IconButtonProps {
    open?: boolean;
}

export const AccountIconButton = styled(IconButton, { shouldForwardProp: (prop) => prop !== 'open' })<AccountIconButtonProps>(({ theme, open }) => ({
    padding: 0,
    '&:before': {
        ...(open && {
            zIndex: 1,
            content: "''",
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            position: 'absolute',
            bgcolor: alpha(theme.palette.grey[900], 0.8),
        }),
    },
}));


interface CustomPopoverProps extends Omit<PopoverProps, 'open'> {
    open?: boolean;
};

export const CustomPopover = styled(Popover)<CustomPopoverProps>(({ theme, open }) => ({
    top: 5,
    '& .MuiMenuItem-root': {
        typography: theme.typography.body2,
        borderRadius: 0.75,
        
    },
    ...(open && {
        p: 0,
        mt: 1.5,
        ml: 0.75,
        width: 200,
    }),
}));