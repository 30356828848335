import { useEffect, useState } from "react";
import { ISubscription } from "../entity/subscriptions/ISubscription";
import { updateSubscriptionUseCase } from "../useCases/updateSubscriptionUseCase";
import { getSubscriptionsUseCase } from "../useCases/getSubscriptionsUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

export const useSubscriptionsTableItem = (item: ISubscription) => {
    const { t } = useUiContext();
    const [price, setPrice] = useState("");

    useEffect(() => {
        setPrice(item.price);
    }, [item]);

    const onChangePrice = (value: any, field: string) => {
        const regex = /^\d*\.?\d{0,2}$/;
        if (value === "" || regex.test(value)) {
            setPrice(value);
        }
    };

    const handleBlur = async () => {
        if (item.price !== price) {
            let editedPrice = price;

            const [integerPart, decimalPart] = editedPrice.split('.');
            if (integerPart.length > 6) {
                toastService.onError(t('subscriptions.tooMuchSymbols'));
                setPrice(item.price);
                return;
            };

            if (/^0?\.?0{0,2}$/.test(editedPrice)) {
                editedPrice = "0.01";
            } else if (editedPrice.includes('.')) {
                const decimalPart = editedPrice.split('.')[1] || "";

                editedPrice = decimalPart.length < 2
                    ? editedPrice + '0'.repeat(2 - decimalPart.length)
                    : editedPrice;
            } else if (editedPrice !== "") {
                editedPrice = editedPrice + '.00';
            }

            editedPrice = editedPrice || '0.01';
            setPrice(editedPrice);

            toastService.onSuccess(t('subscriptions.priceChanged'));
            const res = await updateSubscriptionUseCase({ id: item.id, price: parseFloat(editedPrice) });
            if (res?.success) {
                await getSubscriptionsUseCase();
            }
        }
    };

    return { onChangePrice, price, handleBlur }
};