import React, { FC } from 'react';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, FormControlLabel, Box, FormHelperText, Button, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Iconify } from '../../../../../UIKit/Iconify';
import { useUiContext } from 'src/UIProvider';
import { useAuthorization } from 'src/modules/authentication/presenters/useAuthorization';
import { observer } from 'mobx-react';
import { EmailTextfield, HelperText, PasswordTextfield } from './styles';

export const AuthForm: FC = observer(() => {
    const { t } = useUiContext();
    const { isLoading, showPassword, email, password, onLogin, onShowPassword, onChangEmail, onChangPassword, errors } = useAuthorization();

    return (
        <Box>
            <Stack alignItems="center" mb={3}>
                <img src='/assets/logo.svg' alt='logo' width={300} height={300} />
            </Stack>
            <Stack spacing={3}>
                <EmailTextfield
                    name="email"
                    label={t('auth.email')}
                    value={email}
                    onChange={onChangEmail}
                    helperText={<HelperText>{errors.email}</HelperText>}
                />
                <PasswordTextfield
                    name="password"
                    label={t('auth.password')}
                    value={password}
                    onChange={onChangPassword}
                    type={showPassword ? 'text' : 'password'}
                    helperText={<HelperText>{errors.password}</HelperText>}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={onShowPassword} edge="end">
                                    <Iconify icon={!showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>
            <Stack my={3}>
                <LoadingButton loading={isLoading} fullWidth size="large" type="submit" variant="contained" onClick={onLogin} disabled={!(!!email && !!password)}>
                    {t('auth.signIn')}
                </LoadingButton>
            </Stack>
        </Box>
    );
})
