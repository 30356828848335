import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, Stack, IconButton, Popover } from '@mui/material';
import { useUiContext } from 'src/UIProvider';

export default function LanguagePopover() {
  const { t } = useUiContext();

  const LANGS = [
    {
      value: 'de',
      label: t('locale.german'),
      icon: '/assets/icons/ic_flag_de.svg',
    },
    {
      value: 'en',
      label: t('locale.english'),
      icon: '/assets/icons/ic_flag_en.svg',
    },
    
    // {
    //   value: 'fr',
    //   label: 'French',
    //   icon: '/assets/icons/ic_flag_fr.svg',
    // },
  ];
  
  const { setLocale, locale } = useUiContext();
  const [open, setOpen] = useState(null);
  const selectedLocale = LANGS.find(lang => lang.value === locale);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLocale = (lang) => {
    setOpen(null);
    setLocale(lang)
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <img src={selectedLocale.icon} alt={selectedLocale.label} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem key={option.value} selected={option.value === selectedLocale.value} onClick={() => handleLocale(option.value)}>
              <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />
              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
