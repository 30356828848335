import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";

class ResourcesService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    create = async (link: string, entityId: number, body: FormData): Promise<IResponse<any>> => {
        try {
            const url = link + '/' + entityId + '/content';
            const response = await this.requester.postFormData(url, body);
            return response;
        } catch (error) {
            console.warn('ResourcesService -> create: ', link, error);
            return { isError: true, data: null, message: '' } as any;
        }
    } 

    update = async (link: string, entityId: number, body: FormData) => {
        try {
            const url = link + '/' + entityId + '/content/' + body.get('id');
            const response = await this.requester.putFormData(url, body);
            return response;
        } catch (error) {
            console.warn('ResourcesService -> update: ', link, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    delete = async (link: string, entityId: number, id: number): Promise<IResponse<any>> => {
        try {
            const url = link + '/' + entityId + '/content/' + id;
            const response = await this.requester.delete(url);
            return response;
        } catch (error) {
            console.warn('ResourcesService -> delete: ', link, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

}

export const resourcesService = new ResourcesService(requester, links);