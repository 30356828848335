import React, { FC } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { INewsClient } from 'src/modules-client/news/entity/newsClient/INewsClient';
import { useNavigate } from 'react-router-dom';

interface IPostCard {
    item: INewsClient
}

export const PostCard: FC<IPostCard> = ({ item }) => {
    const navigate = useNavigate();
    const createdAt = new Date(item.createdAt).toLocaleDateString();

    const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        navigate('/news/articles/' + item.slug);
    }

    return (
        <Grid item xs={12} md={6}>
            <CardActionArea component="a" href="#" onClick={onClick}>
                <Card sx={{ display: 'flex' }}>
                    <CardContent sx={{ flex: 1 }}>
                        <Typography component="h2" variant="h5">{item.name}</Typography>
                        <Typography variant="subtitle1" color="text.secondary">{createdAt}</Typography>
                        <Typography variant="subtitle1" paragraph>{item.description}</Typography>
                        <Typography variant="subtitle1" color="primary">Continue reading...</Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        sx={{ width: 160, display: { xs: 'none', sm: 'block' } }}
                        image={item.image?.path}
                        alt={item.name}
                    />
                </Card>
            </CardActionArea>
        </Grid>
    );
}