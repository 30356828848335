import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFeaturesUseCase } from "../useCases/getFeaturesUseCase";

export const useFeatures = () => {
    const navigate = useNavigate();

    useEffect(() => {
        getFeaturesUseCase();
    }, []);

    const onGoToCreateFeatures = useCallback(() => {
        navigate('/dashboard/features/create');
    }, []);

    return { onGoToCreateFeatures };
}
