import React, { FC } from 'react';
import { Card, Container, Grid } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { Select } from 'src/UIKit/Select';
import { observer } from 'mobx-react-lite';
import { CategoryImageDropZone } from '../components/categoryImageDropZone';
import { useCategoryEditing } from '../../presenters/useCategoryEditing';
import { TextInput } from 'src/UIKit/TextInput';
import { CheckboxAutocompleteSingle } from '../components/autocompleteTree';

export const CategoryEditingView: FC = observer(() => {
    const { t } = useUiContext();
    const { category, options, parent, errors, onChange, onUpdateCategory, categories, onSelectCategory, onChangeCategory, loading } = useCategoryEditing();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('categories.editCategory')} buttonTitle={t('common.save')} onPress={onUpdateCategory} view="categoryUpdate" isDisabledButton={loading}/> 
            <CategoryImageDropZone />
            <Card sx={{ p: 2, mb: 2 }}>
                <TextInput label={t('common.name')} name='name' onChange={onChange} value={category?.name} error={errors} required />
                {/* <TextInput label={t('common.description')} name='description' onChange={onChange} value={category?.description} /> */}
                {/* <TextInput label={t('common.slug')} name='slug' onChange={onChange} value={category?.slug} error={errors} required isDisabled/> */}
                {/* <Select label={t('categories.parentCategory')} name='parent_id' onChange={onChange} value={parent?.name || ''} options={options} /> */}
                <CheckboxAutocompleteSingle title={t('categories.parentCategory')} optionList={categories} onSelect={onSelectCategory} value={parent?.id} onChange={onChangeCategory} error={errors} name='category_id' isDisabled={true}/>
            </Card>
        </Container>
    );
})
