export const styles = {
    imageContainer: {
        pt: '100%',
        position: 'relative'
    },
    image: {
        top: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        position: 'absolute',
    } as any,
    contextMenu: {
        position: 'absolute',
        right: 5,
        top: 5
    },
}