export const en = {
    common: {
        create: 'Create',
        edit: 'Edit',
        delete: 'Delete',
        save: 'Save',
        close: 'Close',
        send: 'Send',
        active: 'active',
        noActive: 'no active',
        name: 'Name',
        description: 'Description',
        slug: 'Slug',
        none: 'None',
        image: 'Image',
        parent: 'Parent',
        status: 'Status',
        actions: 'Actions',
        free: "Free",
        noFree: "No free",
        watch: "Watch",
        show: "Shown",
        hide: "Hidden",
        waitingForLoading: "please wait, content is loading ...",
        message: {
            pressToAddFile: 'Press to add file or drop here',
            imageFormats: '(svg, png, jpg or jpeg formats, max size 1mb)',
        },
        error: {
            notEmpty: 'Should not be empty',
            fileSize: 'File size should be less than or equal to 1 MB',
            invalidUrl: 'URL is not correct'
        }
    },
    locale: {
        english: 'English',
        german: 'German',
    },
    auth: {
        email: 'Email address',
        password: 'Password',
        signIn: 'Sign in',
        signOut: 'Sign out',
        error: {
            email: 'Email address is invalid',
            password: 'The field must be at least 8 characters long',
        }
    },
    user:{
        fullName: 'Full Name'
    },
    categories: {
        categories: 'Categories',
        category: 'Category',
        editCategory: 'Edit category',
        categoryIcon: 'Category icon',
        parentCategory: 'Parent category',
        message: {
            categoryUpdated: 'Category updated',
            categorySwapped: 'Category swapped',
            categoryAccessSwitched: 'Category access switched',
        }
    },
    content: {
        content: 'Content',
        createContent: 'Create content',
        editContent: 'Edit content',
        searchByName: 'Search by name',
        contentIcon: 'Content icon',
        parentCategory: 'Parent category',
        message: {
            contentCreated: 'Content created',
            contentUpdated: 'Content updated',
            contentRemoved: 'Content removed',
        }
    },
    resource: {
        resources: 'Resources',
        createResource: 'Create resource',
        resourceType: 'Resource type',
        sortNumber: 'Sort number',
        message: {
            resourceCreated: 'Resource created',
            resourceUpdated: 'Resource updated',
            resourceRemoved: 'Resource removed',
        },
        types: {
            html: 'Text',
            video: 'Video',
            audio: 'Audio',
            image: 'Image',
        }
    },
    notifications: {
        inform: "Inform",
        notifications: "Notifications",
        notification: "Notification",
        createNotification: "Create notification",
        editNotification: "Edit notification",
        newNotification: 'New notification',
        title: 'Title',
        enterTitle: 'Enter title',
        text: 'Text',
        imageUrl: 'Image URL',
        link: 'Link',
        putOffNotification: 'Put off notification',
        timeSending: 'Notification time',
        chooseTime: 'choose time',
        searchNotifications: 'Search notifications',
        ready: 'Waiting to be sent',
        pending: 'Pending',
        error: 'Error',
        completed: 'Completed',
        deleted: 'Deleted',
        image: 'Image',
        cancel: 'Cancel',
        today: 'Today',
        chhoseDate: 'Choose date',
        chooseDateAndTime: 'Choose date and time',
        clear: 'Clear',
        timeSet: 'Time is set',
        details: 'Notification details',
        header: 'Headline',
        content: 'Content',
        status: 'Status',
        time: 'Time',
        preview: 'Notififcation preview',
        notificationSent: 'Notification sent',
        table: {
            image: 'Image',
            name: 'Name',
            content: 'Content',
            status: 'Status',
            actions: 'Actions',
            sent: 'Sent',
        }
    },
    banner: {
        banner: "Banner", 
        editBanner: "Edit banner", 
        message: {
            bannerUpdated: "Banner updated"
        }
    },
    subscriptions: {
        subscriptions: "Subscriptions",
        priceChanged: "Price changed",
        tooMuchSymbols: "The price must be less than 7 digits",
        name: "Name",
        type: "Type",
        duration: "Duration",
        created: "Created",
        currency: "Currency",
        price: "Price",
        day: "Day",
        week: "Week",
        month: "Month",
        year: "Year"
    },



    first_name: 'First name',
    last_name: 'Last name',
    email: 'Email',
    phone: 'Phone',
    link: 'Link',
    position: 'Position',
    skills: 'Skills',
    status: 'Status',
    rating: 'Rating',
    comment: 'Comment',
    sortNumber: 'Sort number',
    file: 'File',
    close: 'Close',
    slug: 'Slug',
    price: 'Price',
    currencyName: 'Currency name',
    currencyShortName: 'Currency short name',
    currencies: 'Currencies',
    addCurrency: 'Add currency',
    languageName: 'Language name',
    languageShortName: 'Language short name',
    addLanguage: 'Add language',
    languages: 'Languages',
    productImages: 'Product images',
    newProduct: 'New product',
    productCategory: 'Product category',
    products: 'Products',
    createProduct: 'Create product',
    editProduct: 'Edit product',
    editCategory: 'Edit category',
    save: 'Save',
    categoryIcon: 'Category icon',
    imageFormats: '(svg, png, jpg or jpeg formats, max size 1mb)',
    pressForAddFileOrDropHere: 'Press for add file or drop here',
    active: 'Active',
    inactive: 'Inactive',
    none: 'None',
    parentCategory: 'Parent category',
    description: 'Description',
    name: 'Name',
    newCategory: 'New category',
    createCategory: 'Create category',
    statistics: 'Statistics',
    totalSend: 'Total send',
    success: 'Success',
    failed: 'Failed',
    notificationName: 'Notification name',
    editNotification: 'Edit notification',
    updateNotification: 'Update notification',
    cancel: 'Cancel',
    add: 'Add',
    owner: 'Owner',
    admin: 'Admin',
    marketing: 'Marketing',
    developer: 'Developer',
    role: 'Role',
    addUserByEmail: 'Add user by email',
    users: 'Users',
    addUser: 'Add user',
    usersAndPermissions: 'Users and permissions',
    apiKey: 'API key',
    copiedToClipboard: 'Copied to clipboard',
    appName: 'NLTDEV',
    signInTo: 'Sign in to',
    getStarted: 'Get started',
    doNotHaveAnAccount: 'Don’t have an account?',
    or: 'or',
    emailAddress: 'Email address',
    password: 'Password',
    login: 'Login',
    forgotPassword: 'Forgot password?',
    yourNotificationServiceProjects: 'Your Notification Service Projects',
    addProject: 'Add project',
    projectName: 'Project name',
    create: 'Create',
    firebaseCredentials: 'Firebase credentials',
    generateFirebaseCredentials: 'Generate Firebase credentials with next steps',
    uploadFile: 'Upload file',
    yourCredentialsAdded: 'Your credentials is added',
    yourCredentialsNotAdded: 'Your credentials is not added',
    // notifications: 'Notifications',
    createNotification: 'Create notification',
    // newNotification: 'New notification',
    title: 'Title',
    text: 'Text',
    enterTitle: 'Enter title',
    yourMessage: 'Your message',
    jsonData: 'JSON data',
    jsonDataPlaceholder: '{}',
    audience: 'Audience',
    searchNotifications: 'Search notifications...',
    edit: 'Edit',
    delete: 'Delete',
    imageUrl: 'Image URL',
    imageUrlPlaceholder: 'https://myimage.png',
    send: 'Send',
    edited: 'Edited',
    by: 'by',
    group: 'Group',
    condition: 'Condition',
    value: 'Value',
    firstName: 'First name',
    lastName: 'Last name',
    confirmPassword: 'Confirm password',
    signUp: 'Sign up',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign in',
    signUpTo: 'Sign up to',
    audienceCondition: 'Audience condition',
    audienceValue: 'Audience value',
    category: 'Category',

    nltdevskeletonIcon: 'NLTDEV Skeleton icon',
    nltdevskeletons: 'NLTDEV Skeletons',
    nltdevskeleton: 'NLTDEV Skeleton',
    createNltdevskeleton: 'Create NLTDEV Skeleton',
    editNltdevskeleton: 'Edit NLTDEV Skeleton',

    newsCategoryIcon: 'NLTDEV news category icon',
    newsCategories: 'News category',
    createNewsCategory: 'Create news category',
    editNewsCategory: 'Edit news category',

    newsIcon: 'NLTDEV news icon',
    news: 'News',
    createNews: 'Create news',
    editNews: 'Edit news',

    featuresIcon: 'NLTDEV features icon',
    features: 'Features',
    createFeatures: 'Create feature',
    editFeature: 'Edit feature',

    resources: 'Resources',
    createResource: 'Create resource',
    editResource: 'Edit resource',
    resourceType: 'Resource type',

    editFeatures: 'Edit features',
    estimate: 'Estimate',
    estimateMarket: 'Estimate market',
    developmentSide: 'Development side',
    frontEnd: 'Front-end',
    backEnd: 'Back-end',
    mobile: 'Mobile',
    markdown: 'Markdown',

    candidates: {
        candidates: 'Candidates',
        createCandidate: 'Create candidate',
        editCandidate: 'Edit candidate',
        candidate: 'Candidate',
    },
    vacancies: {
        vacancies: 'Vacancies',
        createVacancy: 'Create vacancy',
        editVacancy: 'Edit vacancy',
        vacancy: 'Vacancy',
    },
    requirements: 'Requirements',
    benefits: 'Benefits',
    salary: 'Salary',
    applicationDeadline: 'Application deadline',
    available: 'Available',
    employer: 'Employer',
};
