import { toastService } from "src/libraries/toast";
import { bannerService } from "../entity/BannerService";
import { bannerModel } from "../entity/BannerModel";

export const getBannerUseCase = async () => {
    try {
        const response = await bannerService.getBanner();
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            bannerModel.banner = response.data;
        }
    } catch (error) {
        console.warn('getBannerUseCase: ', error);
    }
};