import React, { FC } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import { BrowserRouter } from 'react-router-dom';
import { Router } from './navigation/routes';
import ThemeProvider from './theme';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { UIProvider } from './UIProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Loader } from './UIKit/loader';
import   './index.css';

export const App: FC = () => {
    return (
        <GoogleOAuthProvider clientId="82530179191-d3pv8mh55tbhteghhfefekloa7arc1vv.apps.googleusercontent.com">
            <UIProvider>
                <BrowserRouter>
                    <ThemeProvider>
                        <ReactNotifications />
                        <ScrollToTop />
                        <StyledChart />
                        <Router />
                        <Loader />
                    </ThemeProvider>
                </BrowserRouter>
            </UIProvider>
        </GoogleOAuthProvider>
    );
}
