import { IRequester } from ".";
import { userModel } from "../../entities/user/UserModel";
import axios from 'axios';

class FetchRequester implements IRequester {

    private getHeaders = (headers?: object) => {
        const locale = localStorage.getItem('I18N_LOCALE') || '';
        
        const result: any = {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Accept-Language': locale ? JSON.parse(locale) : 'de'
        };
        if (userModel.token) {
            result['Authorization'] = `Bearer ${userModel.token}`;
        }
        if (headers) {
            Object.assign(result, headers);
        }
        return result;
    }

    patchFormData = async (url: string, data: FormData) => {
        try {
            const response = await axios.patch(url, data, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userModel?.token}`
                }
            });
            return this.processingResponse({ data: response.data, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> postFormData: ', error);
            return this.processingResponse({ data: error.response.data, status: error.response.status });
        }
    }

    postFormData = async (url: string, data: FormData) => {
        try {
            const response = await axios.post(url, data, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userModel?.token}`
                }
            });
            return this.processingResponse({ data: response.data, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> postFormData: ', error);
            return this.processingResponse({ data: error.response.data, status: error.response.status });
        }
    }

    putFormData = async (url: string, data: FormData) => {
        try {
            const response = await axios.put(url, data, {
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${userModel?.token}`
                }
            });
            return this.processingResponse({ data: response.data, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> postFormData: ', error);
            return this.processingResponse({ data: error.response.data, status: error.response.status });
        }
    }

    post = async (url: string, body?: object, headers?: object): Promise<any> => {
        try {
            const config: RequestInit = {
                method: 'POST',
                headers: this.getHeaders(headers),
                body: body ? JSON.stringify(body) : undefined,
            };
            const response = await fetch(url, config);
            const result = await response.json();
            return this.processingResponse({ data: result, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> post: ', error);
            return this.processingResponse({ data: error, status: error.status });
        }
    }

    delete = async (url: string, body?: object, headers?: object): Promise<any> => {
        try {
            const config: RequestInit = {
                method: 'DELETE',
                headers: this.getHeaders(headers),
                body: body ? JSON.stringify(body) : undefined,
            };
            const response = await fetch(url, config);
            const result = await response.json();
            return this.processingResponse({ data: result, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> post: ', error);
            return this.processingResponse({ data: error, status: error.status });
        }
    }

    put = async (url: string, body?: object, headers?: object): Promise<any> => {
        try {
            const config: RequestInit = {
                method: 'PUT',
                headers: this.getHeaders(headers),
                body: body ? JSON.stringify(body) : undefined,
            };
            const response = await fetch(url, config);
            const result = await response.json();
            return this.processingResponse({ data: result, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> post: ', error);
            return this.processingResponse({ data: error.data, status: error.status });
        }
    }

    patch = async (url: string, body?: object, headers?: object): Promise<any> => {
        try {
            const config: RequestInit = {
                method: 'PATCH',
                headers: this.getHeaders(headers),
                body: body ? JSON.stringify(body) : undefined,
            };
            const response = await fetch(url, config);
            const result = await response.json();
            return this.processingResponse({ data: result, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> post: ', error);
            return this.processingResponse({ data: error, status: error.status });
        }
    }

    get = async (url: string, headers?: object): Promise<any> => {
        try {
            const config: RequestInit = {
                method: 'GET',
                headers: this.getHeaders(headers),
            };
            const response = await fetch(url, config);
            const result = await response.json();
            return this.processingResponse({ data: result, status: response.status });
        } catch (error: any) {
            console.warn('FetchRequester -> get: ', error);
            return this.processingResponse({ data: error, status: error.status });
        }
    }

    private processingResponse = (response: any) => {
        let result: any = { isError: true, message: '' };
        if (response?.status < 400) {
            result = { isError: false, data: response.data };
        } else {
            console.error('FetchRequester -> processingResponse: ', response);
            result = { isError: true, message: response?.data?.message || response?.data?.data?.message, errors: response?.data?.errors };
        }
        return result;
    }

}

export const requester = new FetchRequester();
