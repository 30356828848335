export const styles = {
    container: {
        width: 400,
        height: 115,
        my: 2,
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        overflow: 'hidden',
        borderRadius: '15px',
        boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
    },
    image: {
        height: 50,
        width: 50,
        maxHeight: 50,
        maxWidth: 50,
        objectFit: 'cover',
        ml: 2,
        mb: 1
    },
    bell: {
        mr: 1
    },
    data: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center'
    },
    header: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center',
    },
    dataBox: { 
        flex: 1,
        gap: '10px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    dataText: { 
        whiteSpace: 'nowrap', 
        overflow: 'hidden', 
        textOverflow: 'ellipsis',
    }
}