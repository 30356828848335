import { toastService } from "src/libraries/toast";
import { getVacanciesUseCase } from "./getVacanciesUseCase";
import { UpdateVacancyDto } from "../entity/vacancies/dto/update-vacancy-dto";
import { vacanciesService } from "../entity/vacancies/VacanciesService";
import { vacanciesModel } from "../entity/vacancies/VacanciesModel";

export const updateNewsUseCase = async (body: UpdateVacancyDto) => {
    try {
        const formData = new FormData();
        Object.keys(body).forEach(key => {
            if (key === 'image' && body?.image && !body.image?.path?.includes('http')) {
                formData.append('image', body.image, body.image.name)
            } else if (key === 'image' && !body.image?.path?.includes('http')) {
                formData.append('image', 'null')
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await vacanciesService.update(body.id, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Success');
            vacanciesModel.current = response.data;
            await getVacanciesUseCase();
        }
        return response;
    } catch (error) {
        console.warn('updateNewsUseCase: ', error);
    }
}  