import { Button, IconButton, MenuItem, Popover, TableCell, TableRow, Typography } from '@mui/material';
import React, { FC, memo, useCallback, useState } from 'react';
import { Iconify } from 'src/UIKit/Iconify';
import { INotification } from 'src/modules/notifications/entity/INotification';
import { useUiContext } from 'src/UIProvider';
import { styles } from './styles';
import { NotificationStatus } from '../../Notifications';

interface Props {
    item: INotification;
    onPress: (id: number) => void;
    onDeleteNotification: (id: number) => void;
    onGoToEditNotification: (id: number) => void;
};

export const NotificationCard: FC<Props> = memo(({ item, onPress, onDeleteNotification, onGoToEditNotification }) => {
    const { t } = useUiContext();
    const [open, setOpen] = useState(null);
    const isDeleted = item.status === NotificationStatus.DELETED;
    const tabOptions = {
        READY: t('notifications.ready'),
        PENDING: t('notifications.pending'),
        COMPLETED: t('notifications.completed'),
        ERROR: t('notifications.error'),
        DELETED: t('notifications.deleted'),
    };

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const onHandleClick = () => {
        !isDeleted && onPress(item.id);
    };

    const onHandleDelete = () => {
        onDeleteNotification(item.id);
    };

    const onHandleEdit = () => {
        onGoToEditNotification(item.id);
    };

    return (
        <>
            <TableRow tabIndex={-1} role="checkbox" onClick={onHandleClick} sx={{cursor: isDeleted ? 'inherit' : 'pointer', '&:hover': { backgroundColor: '#f2f3f5' }}} >
                <TableCell align="center" width="20%">
                    {item?.image ? <img src={item.image.path} alt="image" style={styles.image as any} /> : 'no image'}
                </TableCell>
                <TableCell component="th" scope="row" padding="none" align="center" width="20%">
                    <Button >
                        <Typography variant="subtitle2" sx={styles.text}>
                            {item.title}
                        </Typography>
                    </Button> 
                </TableCell>
                <TableCell align='center' width="40%">
                    <Typography variant="subtitle2" sx={styles.text}>{item.body}</Typography>
                </TableCell>
                <TableCell align='center' width="5%">
                    <Typography variant="subtitle2" sx={styles.text}>{item.count_sent || 0}</Typography>
                </TableCell>
                <TableCell align="center" width="20%">
                    <Typography variant="subtitle2" noWrap>
                        {tabOptions[item.status]}
                    </Typography>
                </TableCell>
                <TableCell align="right" width="20%">
                    <IconButton size="large" color="inherit" onClick={handleOpenMenu}>
                        <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                </TableCell>
            </TableRow>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ sx: { p: 1, width: 140, '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75, }, }, }}
            >
                <MenuItem onClick={onHandleEdit}>
                    <Iconify icon={isDeleted ? 'mdi:eye-outline' : 'eva:edit-fill'} sx={{ mr: 2 }} />{isDeleted ? t('common.watch') : t('common.edit')}
                </MenuItem>
                {!isDeleted &&
                    <MenuItem sx={{ color: 'error.main' }} onClick={onHandleDelete}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />{t('common.delete')}
                    </MenuItem>}
            </Popover>
        </>
    );
});
