import React, { FC } from 'react';
import { Card, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { TableHeader } from 'src/UIKit/TableHeader';
import { useFeaturesTable } from 'src/modules/features/presenters/useFeaturesTable';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { FeaturesTableRow } from '../tableRow';
import { featuresModel } from 'src/modules/features/entity/features/FeaturesModel';

const TABLE_HEAD = ['Image', 'Name', 'Description', 'Price', 'Estimate', 'Market estimate', 'Development side', 'Slug', 'Status', ''];

export const FeaturesTable: FC = observer(() => {
    const { t } = useUiContext();
    const { rowsPerPage, page, search, onSearch, onChangePage, onChangeRowsPerPage } = useFeaturesTable();

    return (
        <Card>˝
            <ListToolbar search={search} onSearch={onSearch} placeholder={t('features')} />
            <Scrollbar >
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHeader headLabels={TABLE_HEAD} />
                        <TableBody>
                            {featuresModel.features?.rows?.map((item) => (<FeaturesTableRow item={item} key={item.id} />))}
                        </TableBody>
                        {/* <TableEmptyList /> */}
                    </Table>
                </TableContainer>
            </Scrollbar>
            <TablePagination
                rowsPerPageOptions={[10, 20]}
                component="div"
                count={featuresModel.features?.count || 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
            />
        </Card>
    );
})
