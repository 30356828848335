import { FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EmailTextfield = styled(TextField)`
  & input:-webkit-autofill {
    box-shadow: 0 0 0 30px #F5F5F5 inset !important;
    -webkit-text-fill-color: #333 !important;
  }
`;

export const PasswordTextfield = styled(TextField)`
  & input:-webkit-autofill {
    box-shadow: 0 0 0 30px #F5F5F5 inset !important;
    -webkit-text-fill-color: #333 !important;
  }
`;

export const HelperText = styled(FormHelperText)`
  color: ${({ theme }) => theme.palette.error.main};
  margin: 0;
  margin-top: 0.2rem;
`;