import React, { FC } from 'react';
import { Box } from '@mui/material';
import { IResources } from 'src/modules/resources/entity/resources/IResources';

interface IProps {
    item: IResources;
}

export const PreviewImage: FC<IProps> = ({ item }) => {

    return (
        <Box sx={{ marginTop: '10px', marginBottom: '10px', display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <img src={item?.file?.path} style={{ width: '400px', objectFit: 'contain' }} />
        </Box>
    )
}
