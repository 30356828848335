import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { IBanner } from "./IBanner";
import { ILinks, links } from "src/Links";

class BannerService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    getBanner = async (): Promise<IResponse<IBanner>> => {
        try {
            const response = await this.requester.get(this.links.banners);
            return response;
        } catch (error) {
            console.warn('BannerService -> getBanner: ', this.links.banners, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };

    updateBanner = async (body: FormData): Promise<IResponse<IBanner>> => {
        try {
            const response = await this.requester.patchFormData(this.links.banners, body);
            return response;
        } catch (error) {
            console.warn('BannerService -> updateBanner: ', this.links.banners, error);
            return { isError: true, data: null, message: '' } as any;
        }
    };
};

export const bannerService = new BannerService(requester, links);
