import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { z } from 'zod';
import { getNewsByIdUseCase } from "../useCases/getNewsByIdUseCase";
import { updateNewsUseCase } from "../useCases/updateNewsUseCase";
import { newsModel } from "../entity/news/NewsModel";
import { newsCategoryModel } from "src/modules/newsCategories/entity/newsCategories/NewsCategoryModel";
import { getNewsCategoriesUseCase } from "src/modules/newsCategories/useCases/getNewsCategoriesUseCase";
import { INewsCategory } from "src/modules/newsCategories/entity/newsCategories/INewsCategory";

const newsSchema = z.object({
    name: z.string().min(1, { message: "Not empty" }),
    description: z.string().nullable(),
    slug: z.string().min(1, { message: "Not empty" }),
    available: z.boolean(),
    image: z.any().refine((file) => (file && file.size && file.size <= 1 * 1024 * 1024), { message: "File size should be less than or equal to 1 MB" }).nullable().optional(),
});

export const useNewsEditing = () => {
    const id = useParams().id;
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ name: '', description: '', slug: '', image: '', category_id: '' });
    const news = newsModel.newsOne;
    const navigate = useNavigate();
    const categories = newsCategoryModel.newsCategories;

    useEffect(() => {
        if (!categories.length) {
            getNewsCategoriesUseCase();
        }
        getNewsByIdUseCase(Number(id));
        return () => { newsModel.newsOne = null; }
    }, []);

    const parent = categories?.find((item: INewsCategory) => item.id === news?.category_id);

    const options = categories?.map((item: INewsCategory) => ({ id: item.id, name: item.name, value: item.id }));

    const onChange = (value: any, field: string) => {
        if (newsModel.newsOne) {
            newsModel.newsOne = { ...newsModel.newsOne, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
        }
    }

    const onUpdateNews = async () => {
        setLoading(true);
        const result: any = newsSchema.safeParse({ ...newsModel.newsOne });
        if (result.success && newsModel.newsOne) {
            await updateNewsUseCase(newsModel.newsOne as any);
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        }
        setLoading(false);
    }

    const onRefresh = async () => {
        await getNewsByIdUseCase(Number(id));
    };

    return { news, errors, onChange, onUpdateNews, onRefresh, options, parent };

}