import { IRequester, requester } from "src/libraries/requester";
import { IResponse } from "src/libraries/requester/IRequester/IResponse";
import { ILinks, links } from "src/Links";
import { IFeatures } from "./IFeatures";
import { GetFeaturesDto } from "./dto/list-features-dto";

class FeaturesService {
    constructor(
        private requester: IRequester,
        private links: ILinks,
    ) { }

    create = async (body: FormData): Promise<IResponse<IFeatures>> => {
        try {
            const response = await this.requester.postFormData(this.links.features, body);
            return response;
        } catch (error) {
            console.warn('FeaturesService -> create: ', this.links.features, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    list = async (body?: GetFeaturesDto): Promise<IResponse<any>> => {
        try {
            const url = new URL(this.links.features);
            if (body) {
                Object.keys(body).forEach(key => url.searchParams.append(key, body[key]));
            }
            const response = await this.requester.get(url.href);
            return response;
        } catch (error) {
            console.warn('FeaturesService -> list: ', this.links.features, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    update = async (id: number, body: FormData): Promise<IResponse<IFeatures>> => {
        try {
            const url = this.links.features + '/' + id;
            const response = await this.requester.patchFormData(url, body);
            return response;
        } catch (error) {
            console.warn('FeaturesService -> update: ', this.links.features, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    get = async (id: number | string): Promise<IResponse<IFeatures>> => {
        try {
            const response = await this.requester.get(this.links.features + '/' + id);
            return response;
        } catch (error) {
            console.warn('FeaturesService -> get: ', this.links.features, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

    delete = async (id: number): Promise<IResponse<any>> => {
        try {
            const response = await this.requester.delete(this.links.features + id);
            return response;
        } catch (error) {
            console.warn('FeaturesService -> delete: ', this.links.features, error);
            return { isError: true, data: null, message: '' } as any;
        }
    }

}

export const featuresService = new FeaturesService(requester, links);