import { useState } from "react";
import { ICategory } from "../entity/categories/ICategory";
import { deleteCategoryUseCase } from "../useCases/deleteCategoryUseCase";
import { useNavigate } from "react-router-dom";
import { updateCategoryUseCase } from "../useCases/updateCategoryUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

export const useCategoryListItem = (category: ICategory) => {
    const { t } = useUiContext();
    const [open, setOpen] = useState(false);
    const [openMenu, setOpenMenu] = useState(null);
    const navigate = useNavigate();

    const handleOpenMenu = (event) => {
        setOpenMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const onOpenCloseAccordion = () => {
        setOpen(!open);
    };

    const onEdit = () => {
        navigate(`/dashboard/categories/edit/${category.id}`);
    };

    const onActiveSwitch = async () => {
        const result: any = await updateCategoryUseCase({ id: category.id, available: !category.available });
        if (!result?.isError) {
            toastService.onSuccess(t('categories.message.categoryAccessSwitched'));
        }
    };

    const onDelete = async () => {
        await deleteCategoryUseCase(category.id);
    }

    return { onDelete, open, onOpenCloseAccordion, handleOpenMenu, openMenu, handleCloseMenu, onEdit, onActiveSwitch };

}