export interface ILinks {
    readonly signIn: string;
    readonly users: string;
    readonly categories: string;
    readonly banners: string;
    readonly notifications: string;
    readonly products: string;
    readonly nltdevskeletons: string;
    readonly news: string;
    readonly newsCategory: string;
    readonly features: string;
    readonly vacancies: string;
    readonly subscriptions: string;
    readonly newsClient: string;
    readonly newsCategoryClient: string;
}

class Links implements ILinks {
    private dev = 'https://slonova-dev.pp.ua/api';
    private prod = 'https://app.christinaslonova.com/api';
    private _domain = this.dev;
    private _links = {
        readFiles: `${this._domain}/read-files`,
        signIn: `${this._domain}/auth-email/admin/sign-in`,
        users: `${this._domain}/users`,
        categories: `${this._domain}/admin/categories`,
        banners: `${this._domain}/admin/banners`,
        notifications: `${this._domain}/admin/notifications`,
        products: `${this._domain}/admin/category-items`,
        news: `${this._domain}/admin/category-items`,
        newsCategory: `${this._domain}/admin/news-categories`,
        features: `${this._domain}/admin/features`,
        vacancies: `${this._domain}/admin/vacancies`,
        subscriptions: `${this._domain}/admin/product`,
        newsClient: `${this._domain}/news`,
        newsCategoryClient: `${this._domain}/news-categories`,
    };

    public get signIn() { return this._links.signIn; }
    public get users() { return this._links.users; }
    public get categories() { return this._links.categories; }
    public get banners() { return this._links.banners; }
    public get notifications() { return this._links.notifications; }
    public get products() { return this._links.products; }
    public get news() { return this._links.news; }
    public get newsCategory() { return this._links.newsCategory; }
    public get nltdevskeletons() { return this._links.products; }
    public get features() { return this._links.features; }
    public get vacancies() { return this._links.vacancies; }
    public get subscriptions() { return this._links.subscriptions; }
    public get newsClient() { return this._links.newsClient; }
    public get newsCategoryClient() { return this._links.newsCategoryClient; }

};

export const links = new Links();
