import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { notificationsModel } from "../entity/NotificationModel";
import { getNotificationUseCase } from "../useCases/getNotificationUseCase";
import { deleteNotificationUseCase } from "../useCases/deleteNotificationUseCase";
import { sendNotificationUseCase } from "../useCases/sendNotificationUseCase";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";

export const useNotificationDetails = () => {
    const { id } = useParams();
    const { t } = useUiContext();
    const notification = notificationsModel.notification;
    const navigate = useNavigate();

    useEffect(() => {
        getNotificationUseCase(Number(id));
        return () => {
            notificationsModel.notification = null;
        }
    }, []);

    const editedDate = useMemo(() => {
        if (notification?.updatedAt) {
            return moment(notification.updatedAt).format('DD/MM/YYYY');
        }
        return ''
    }, [notification])

    const onSend = async () => {
        if (id) {
            const result = await sendNotificationUseCase(Number(id));
            if(!result?.isError){
                toastService.onSuccess(t('notifications.notificationSent'));
                navigate('/dashboard/notifications', { replace: true });
            }
        }
    };

    const onDelete = async () => {
        if (id) {
            const response = await deleteNotificationUseCase(Number(id));
            if (!response?.isError) {
                navigate('/dashboard/notifications', { replace: true });
            }
        }
    };

    const onGoToEditNotification = useCallback(() => {
        navigate(`/dashboard/notifications/edit/${id}`);
    }, []);

    return { notification, editedDate, onSend, onDelete, onGoToEditNotification };
}