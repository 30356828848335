import { toastService } from "src/libraries/toast";
import { UpdateBannerDto } from "../entity/dto/update-banner-dto";
import { bannerService } from "../entity/BannerService";
import { bannerModel } from "../entity/BannerModel";
import { IBanner } from "../entity/IBanner";

export const updateBannerUseCase = async (payload: UpdateBannerDto): Promise<IBanner | undefined> => {
    try {
        const formData = new FormData();
        const body = payload;
        
        Object.keys(body).forEach(key => {
            if (key === 'image' && body.image) {
                if (body.image instanceof File) {
                    formData.append('image', body.image, body.image.name);
                }
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });

        const response = await bannerService.updateBanner(formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            return response.data;
        }
    } catch (error) {
        console.warn('updateBannerUseCase: ', error);
    };
};
