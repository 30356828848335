import dayjs from 'dayjs';
import { ChangeEvent, useEffect, useState } from "react";
import { createNotificationUseCase } from "../useCases/createNotificationUseCase";
import { useNavigate } from "react-router-dom";
import { toastService } from "src/libraries/toast";
import { useUiContext } from "src/UIProvider";
import { notificationsModel } from "../entity/NotificationModel";
import { IResources } from "src/modules/resources/entity/resources/IResources";
import { z } from "zod";

const DEFAULT_RESOURCE: any = { name: '', value: '', file: null, type: 'IMAGE', id: 0 };

export const useNotificationCreation = () => {
    const { t } = useUiContext();
    const [loading, setLoading] = useState(false);
    const notification = notificationsModel.notification;
    const [resource, setResource] = useState<IResources>(DEFAULT_RESOURCE);
    const [time, setTime] = useState('');
    const [checked, setChecked] = useState(false);
    const [errors, setErrors] = useState({ title: '', body: '' });
    const navigate = useNavigate();

    const IMAGE_TYPES = [
        { name: t('notifications.image'), id: 'IMAGE' },
        { name: t('notifications.link'), id: 'LINK_IMAGE' }
    ];

    const typeObject = IMAGE_TYPES?.find((item: any) => item.id === notification?.type);

    const notificationSchema = z.object({
        title: z.string().min(1, { message: t('common.error.notEmpty') }),
        body: z.string().min(1, { message: t('common.error.notEmpty') }),
    });
    
    useEffect(() => {
        notificationsModel.notification = { title: '', body: '', type: 'LINK_IMAGE' } as any;
        return () => { notificationsModel.notification = null; }
    }, []);

    const onChange = (value: any, field: string) => {
        if (notificationsModel.notification) {
            notificationsModel.notification = { ...notificationsModel.notification, [field]: value };
            setErrors((prev) => ({ ...prev, [field]: '' }));
            if (field === 'type') {
                notificationsModel.notification = { ...notificationsModel.notification, image: null };
                setResource((prev) => ({ ...prev, file: null }));
            }
        }
    };

    const onChangeFile = (value: any, field: string) => {
        setResource((prev) => ({ ...prev, [field]: value }));
    };

    const onChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        event.target.checked === false && setTime('');
    };

    const onChangeTime = (newDateTime: any) => {
        setTime(newDateTime);
    };

    const onAcceptTime = () => {
        toastService.onSuccess(t('notifications.timeSet'));
    };

    const onCreateNotification = async () => {
        setLoading(true);
        const result: any = notificationSchema.safeParse({ title: notification?.title, body: notification?.body });
        if (result.success && notificationsModel.notification) {
            const body: any = {
                audience: "ALL",
                title: notification?.title || '',
                body: notification?.body || '',
            };
            if(notification?.image || resource.file) body.image = notification?.image || resource.file;
            if(notification?.navigation_url) body.navigation_url = notification?.navigation_url;
            if(time) body.time = dayjs(time).unix() + "000";

            const result = await createNotificationUseCase(body);
            if(result){
                navigate(`/dashboard/notifications/details/${result.id}`);
            }
        } else {
            result.error.errors.map((item: any) => {
                setErrors((prev) => ({ ...prev, [item.path[0]]: item.message }));
            })
        };
    };

    return {
        onCreateNotification, time, checked, onChangeSwitch, onChangeTime, onAcceptTime, onChange, notification, resource, onChangeFile, IMAGE_TYPES,
        typeObject, errors
    };
};