import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface IProps {
    isDisabledButton?: boolean;
    title?: string;
    view?: string;
    buttonTitle?: string;
    onPress?: () => void;
};

export const DashboardHeader: FC<IProps> = ({ title, buttonTitle, onPress, view, isDisabledButton }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h4" gutterBottom>{title}</Typography>
            {view !== 'categoryList' && onPress ?
                <Button variant="contained" onClick={onPress} disabled={isDisabledButton} sx={{ textTransform: 'none' }}>
                    {buttonTitle}
                </Button> : null}
        </Stack>
    )
};
