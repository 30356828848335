import { notificationsModel } from "../entity/NotificationModel";
import { notificationsService } from "../entity/NotificationService";
import { toastService } from "src/libraries/toast";
import { INotification } from "../entity/INotification";
import { UpdateNotificationDto } from "../entity/dto/update-notification-dto";

export const updateNotificationUseCase = async (payload: {id: string | undefined, body: UpdateNotificationDto}): Promise<INotification | undefined> => {
    try {
        const formData = new FormData();
        const body = payload.body;
        Object.keys(body).forEach(key => {
            if (key === 'image' && body.image) {
                if (typeof body.image === 'string') {
                    formData.append('image', body.image);
                } else if (body.image instanceof File) {
                    formData.append('image', body.image, body.image.name);
                }
            } else if (body[key] !== null && body[key] !== undefined) {
                formData.append(key, body[key].toString());
            }
        });
        const response = await notificationsService.uploadNotification(payload.id, formData);
        if (response?.isError) {
            toastService.onError('Some error');
        } else {
            toastService.onSuccess('Notification updated');
            notificationsModel.notification = response.data;
            return response.data;
        }
    } catch (error) {
        console.warn('createNotificationUseCase: ', error);
    }
};
