import React, { FC } from 'react';
import { Card, Container } from '@mui/material';
import { useUiContext } from 'src/UIProvider';
import { observer } from 'mobx-react-lite';
import { Scrollbar } from 'src/UIKit/Scrollbar';
import { DashboardHeader } from 'src/UIKit/DashboardHeader';
import { ListToolbar } from 'src/UIKit/listToolbar';
import { useNewsCategories } from '../../presenters/useNewsCategories';
import { CategoryListContainer } from '../components/categoryListContainer';

export const NewsCategoriesView: FC = observer(() => {
    const { t } = useUiContext();
    const { search, onGoToCreateNewsCategory, onSearch, onDragEndNewsCategory } = useNewsCategories();

    return (
        <Container maxWidth="xl">
            <DashboardHeader title={t('newsCategories')} buttonTitle={t('createNewsCategory')} onPress={onGoToCreateNewsCategory} />
            <Card>
                <ListToolbar search={search} onSearch={onSearch} placeholder={t('newsCategories')} />
                <Scrollbar >
                    <CategoryListContainer onDragEndCategory={onDragEndNewsCategory} />
                </Scrollbar>
            </Card>
        </Container>
    );
})
